import React from "react";
import UploadDocV2 from "../../UploadDoc";
import './ACLS_DocV2.css'
const ACLS_DocV2 = () => {
  const docInfo = [
    {
      "ACLS - Advanced cardiovascular life support - front": 11,
    },
    {
      "ACLS - Advanced cardiovascular life support - back": 82,
    },
  ];
  const styleContainer = {
    width :"337px"
  }


  return (
    <div className="acls-doc-container">
      <UploadDocV2
        docInfo={docInfo}
        backUrl="bls-doc"
        nextUrl="driver-license-doc"
        currentPage={3}
        WizardFlow={114}
        IsMandatory={true}
        skipUrl={"driver-license-doc"}
        style={styleContainer}
      />
    </div>
  );
};

export default ACLS_DocV2;
