import React, { useState, useEffect } from "react";
import "./assets/css/common.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppRouter } from "./routes/router";
import {
  fetchData,
  fetchInboxMessages,
  fetchInterview,
  fetchReceivedBidsStages,
  fetchResumeDetailInit,
  placeBidFetch,
  updateUserInfo,
} from "./redux/actions";
import "./firebaseConfig";
import { useClearCache } from "react-clear-cache";
import { Auth } from "./config/aws-exports";
import { clearAll, getCookie, setCookie } from "./shared/helpers";
import WS from "./webSocket/defaultSocket";
import { useLocation } from 'react-router-dom'
import ErrorModel from "./pages/Error/components/ErrorModel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactGA from 'react-ga4';


function App(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [stage, seStage] = useState("");

  // Google Analytics Initialization
  ReactGA.initialize('G-T5NQCKKG47');

  const userProfile = useSelector((state) => state.profileReducer.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [isTokenExpiredState, setIsTokenExpiredState] = useState(false);

  // const talentToken = getCookie("talentToken");
  const talentToken = useSelector((state) => state.authReducer.user.authToken);

  const RememberMe = getCookie("RememberMe");

  const refreshToken = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = currentUser.signInUserSession;
      currentUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          // fetching and updating the new Token
          const newTalentToken = session.getIdToken().getJwtToken();
          setCookie("talentToken", newTalentToken, true);
          dispatch(updateUserInfo(newTalentToken));
          

          history.push("/auth");
        }
      );
    } catch (err) {
      
    }
  };

  // logging out user incase of expired token and remember me false
  const Logout = async (redirect) => {
    try {
      clearAll();
      dispatch(updateUserInfo(null));
      await Auth.signOut();
      redirect();
      localStorage.removeItem("redirectUrl");
    } catch (error) {}
  };
  const onLogOut = async () => {
    if (location.pathname === "/") {
      dispatch(updateUserInfo(null));
      clearAll();
      await Auth.signOut();
    }
    await Logout(() => window.location.replace("/login"));
  };

  // checking for the expired token
  const isTokenExpiredFunction = () => {
    const token = talentToken;
    const payloadBase64 = token.split(".")[1];
    const decodedJson = Buffer.from(payloadBase64, "base64").toString();
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    const expired = Date.now() >= exp * 1000;

    if (expired && RememberMe === "true") {
      refreshToken();
    }
    setIsTokenExpiredState(expired);
  };

  useEffect(() => {
    if (!talentToken) return;
    // first render check
    isTokenExpiredFunction();

    // interval check
    const isExpiredInterval = setInterval(() => {
      isTokenExpiredFunction();
    }, 10000);

    return () => {
      clearInterval(isExpiredInterval); // Cleanup interval on component unmount or re-render
    };
  }, [talentToken]);

  useEffect(() => {
    if (talentToken && isTokenExpiredState && !RememberMe) {
      setIsTokenExpiredState(false);
      Logout();
    }
  }, [isTokenExpiredState]);

  useEffect(() => {
    // Google Analytics hit page view event
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/sw.js`)
      .then(() => {});
  }

  const receiveStage = (stage) => {
    seStage(stage);
  };

  const pageSize = 20;

  // might stop the zoom in behavior for other reasons on iPhone other than 16px on input
  useEffect(() => {
    if (navigator.userAgent.indexOf("iPhone") > -1) {
      document
        .querySelector("[name=viewport]")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1"
        );
    }
  }, []);

  useEffect(() => {
    const accountType = props?.user?.profile?.AccountTypeCode;

    if (accountType === "EMP" && props?.authToken) {
      window.location.replace("/employer");
    }
  }, [props.user]);

  useEffect(() => {
    if (userProfile?.profile?.AccountId) {
      WS.init(userProfile?.profile?.AccountId);
      WS.onMessage(({ data }) => {
        dispatch(
          fetchInboxMessages("all_bids", "all_conversations", "null", 1, 1)
        );
      });
    }
  }, [userProfile?.profile?.AccountId]);

  const theClick = () => {
    WS.sendMessage(
      JSON.stringify({
        action: "ping", // whatever route we define it'll trigger it
      })
    );
  };

  function checkTabFocused() {
    if (document.visibilityState === "visible") {
      const cognitoUser = Auth.currentAuthenticatedUser();
      cognitoUser
        .then((user) => {})
        .catch((err) => {
          if (getCookie("talentToken") === null) {
            props.sendAuthToken(null);
          }
        });
    } else {
    }
  }
  if (props?.authToken) {
    document.addEventListener("visibilitychange", checkTabFocused);
  }
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  if (!isLatestVersion) {
    emptyCacheStorage();
  }

  // useEffect(() => {
  //   (function () {
  //     window.__insp = window.__insp || [];
  //     window.__insp.push(["wid", process.env.REACT_APP_INSPECTLET_ID]);
  //     var ldinsp = function () {
  //       if (typeof window.__inspld != "undefined") return;
  //       window.__inspld = 1;
  //       var insp = document.createElement("script");
  //       insp.type = "text/javascript";
  //       insp.async = true;
  //       insp.id = "inspsync";
  //       insp.src =
  //         ("https:" == document.location.protocol ? "https" : "http") +
  //         `://cdn.inspectlet.com/inspectlet.js?wid=${process.env.REACT_APP_INSPECTLET_ID}&r=` +
  //         Math.floor(new Date().getTime() / 3600000);
  //       var x = document.getElementsByTagName("script")[0];
  //       x.parentNode.insertBefore(insp, x);
  //     };
  //     setTimeout(ldinsp, 0);
  //   })();
  // }, []);

  useEffect(() => {
    if (userProfile?.WizardFlow > 5) {
      dispatch(fetchReceivedBidsStages());
      dispatch(fetchInterview());
      dispatch(fetchResumeDetailInit());
      dispatch(placeBidFetch("most_recent", null, null, 1, 24));
    }
  }, [userProfile?.WizardFlow]);

  return (
    <React.Fragment>
      {/* <StrictMode> */}
      <div className="page-container">
        {/* <EmailVerification /> */}
        {/* <ErrorModel> */}
        <AppRouter
          authToken={props.authToken}
          shareStage={receiveStage}
          stage={stage}
          userProfile={userProfile}
          isEmailVerified={false}
          location={location.pathname}
          width={screenWidth}
        />
        {/* </ErrorModel> */}
      </div>
      {/* </StrictMode> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.user.authToken,
    loading: state.profileReducer.loading,
    user: state.profileReducer.user,
    error: state.profileReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params) => {
      dispatch(fetchData(params));
    },
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
