import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Alert,
  Col,
  Select,
  Spin,
  Row,
  Card,

  Radio,
  Divider,
  Button,
} from "antd";
import {
  CloseCircleFilled,
  CheckCircleTwoTone,
  CheckCircleFilled,
  CloseCircleTwoTone,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Panel from "./Panel";
import {
  createBid,
  updateBid,
  fetchBidData,
  isModalVisibleAuth,
  updateBidReset,
  fetchInboxMessages,
  fetchMessagesCounts,
} from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCookie,
  getImageByStateCode,
  setCookie,
} from "../../../shared/helpers";
import ToolTips from "../../../components/ToolTip/ToolTips";
import { Convert, Converter } from "easy-currencies";
import Loader from "../../../components/Loader/Loader";
import { calculateFinalAmount } from "../../../assets/helpers";

const BidForm = (props) => {
  var history = useHistory();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;
  const [rate, setRate] = useState(null);
  const [bill, setBill] = useState("");
  const [dollar, setDollar] = useState("");
  const [dollarBill, setDollarBill] = useState("");
  const [dollarClick, setDollarClick] = useState(false);
  const [dollarBillClick, setDollarBillClick] = useState(false);
  const [rateBy, setRateBy] = useState("Hour");
  const [sgFee, setSGFEE] = useState(0);
  const [error, setError] = useState("");
  const [gigTitle, setgigTitle] = useState("");
  const [defaultValue, setDefaultValue] = useState("");

  //useState for bidInputError
  const [bidInputError, setBidInputError] = useState("");
  //useState for taxTermError
  const [taxTermError, setTaxTermError] = useState("");
  const [dimensions, setDimensions] = useState(window.innerWidth);
  //useState for messageError
  const [messageError, setMessageError] = useState("");
  const cookie = getCookie("talentToken");

  const success = useSelector((state) => state.createBidReducers.success);
  const loading = useSelector((state) => state.createBidReducers.loading);
  const Error = useSelector((state) => state.createBidReducers.error);

  const upSuccess = useSelector((state) => state.updateBidReducers.success);
  const upLoading = useSelector((state) => state.updateBidReducers.loading);
  const upError = useSelector((state) => state.updateBidReducers.error);

  const rLoading = useSelector((state) => state.retractBidReducers.loading);
  const rError = useSelector((state) => state.retractBidReducers.error);

  const dataSuccess = useSelector((state) => state.getBidDataReducer.gigs);
  const dataLoading = useSelector((state) => state.getBidDataReducer.loading);
  const dataError = useSelector((state) => state.getBidDataReducer.error);
  const ProfessionCode = useSelector((state) => state.profileReducer.user.profile.ProfessionCode);
  const talentPrivate = useSelector(
    (state) => state?.profileReducer?.talentApiData?.talentPrivate
  );

  //state.authReducer.isModalVisibleAuth
  const isModalVisibleAuthSelector = useSelector(
    (state) => state.authReducer.isModalVisibleAuth
  );

  // const getUser = useSelector(())
  const inputRef = useRef(null);

  const [message, setMessage] = useState(
    dataSuccess?.Message ? dataSuccess?.Message : ""
  );
  const [currency, setcurrency] = useState("$USD");
  const [CurrencySign, setCurrencySign] = useState("");
  const [taxTerm, setTax] = useState("");
  const [bidshow, setBidshow] = useState(true);
  const [upSuccessData, setupSuccessData] = useState(false);
  const tax = ["1099/Corp-to-Corp", "W-2"];
  const [placeholder, showPlaceholder] = useState(false);
  const [Defaultplaceholder, setDefaultplaceholder] = useState(false);
  const [CityNameImg, setCityNameImg] = useState();
  const [RegionCodeImg, setRegionCodeImg] = useState();

  const [span, setSpan] = useState(
    " Your net pay will vary based on factors such as your preferred payment method (W2 or 1099) and tax obligations."
  );
  var date = Date().toLocaleString();
  var onlyNumb = /^[0-9\b]+$/;
  // const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  // const [ isModalVisibleAuth, setIsModalVisibleAuth ] = useState(false);
  const skillgigsFeeRate = 10; // Skillgigs fee rate in percentage (10%)
  let payrollFeeRate = 18; // Payroll fee rate in percentage (18%)

  const { state } = useLocation();
  useEffect(() => {
    setupSuccessData(true);
  }, []);

  // const { title, FacilityName, RegionName, budget } = state;
  const RegionName = state?.RegionName;
  const title = state?.title;
  const FacilityName = state?.FacilityName;
  const budget = state?.budget;
  const JobType = state?.JobType
  const Remote = state?.Remote

  const id = state?.JobOrderId;

  useEffect(() => {
    setCityNameImg(props?.method == "put" ? dataSuccess?.gigs?.Cities?.CityName : RegionName?.CityName );
    setRegionCodeImg(props?.method == "put" ? dataSuccess?.gigs?.Regions?.Code : RegionName?.Location.split(", ")[1] );
    setDefaultplaceholder(false);
  }, [
    dataSuccess?.gigs?.Cities?.CityName, 
    dataSuccess?.gigs?.Regions?.Code,
    RegionName?.CityName,
    RegionName?.Location.split(", ")[1]
  ])

  const handleChange = (value) => {
    setcurrency(value);
    // dispatch(
    //   updateBid(
    setRate(null);
    setBill(null);
    setDollar(null);
    setDollarClick(false);
    setDollarBill(null);
    setDollarBillClick(false);
  };
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  const saveBidToLocalStorage = () => {
    //save state to local storage
    localStorage.setItem("bidData", JSON.stringify(state));
    const gigId = state?.JobOrderId;
    const bidFormData = [
      gigId,
      null,
      null,
      null,
      message,
      date,
      date,
      null,
      rateBy,
      "In Progress",
      rate,
      taxTerm,
      0,
      date,
      sgFee,
      null,
      null,
      date,
      bill,
      currency.substring(1),
    ];
    localStorage.setItem("bid", JSON.stringify(bidFormData));
    localStorage.setItem("redirectTo", "/create-bid");
  };
  // let bidFromLocalStorage;
  const handleSubmit = (e) => {
    const cookie = getCookie("talentToken");

    //multiple if  cases intaxTerm, rateBy, rate, bill are empty, then show error
    if (
      taxTerm === "" ||
      rateBy === "" ||
      rate === "" ||
      message === "" ||
      rate < 5
    ) {
      if (taxTerm === "") {
        setTaxTermError("choose a tax term");
      }
      if (rateBy === "") {
        // setBidInputError("Add your estimated pay package");
        setBidInputError("Add your bid rate");
      }
      if (rate <= 0) {
        // setBidInputError("Minimum Bid amount is 5$");
        setBidInputError("Add your bid rate");
      }
      if (rate === "") {
        setBidInputError("Add your bid rate");
      }
      if (message === "") {
        setMessageError(
          "Add a cover letter to help the employer get to know you better"
        );
      }
      return;
    }

    // if (
    //   taxTerm === "" ||
    //   rateBy === "" ||
    //   rate === "" ||
    //   bill === "" ||
    //   message === ""
    // ) {
    //   setBidInputError("Please choose a bid");
    //   setTaxTermError("Please choose a tax term");
    //   setMessageError("Please add cover message");
    //   return;
    // }

    if (cookie) {
      // e.preventDefault();
      if (props.method == "post") {
        dispatch(
          createBid(
            id,
            null,
            null,
            null,
            // `${message}<br/>Bid Amount: ${bill}`,
            `${message}`,
            date,
            date,
            null,
            rateBy,
            // "In Progress",
            "New",
            rate,
            taxTerm,
            0,
            date,
            sgFee,
            null,
            null,
            date,
            bill,
            currency.substring(1)
          )
        );
      } else if (props.method == "put") {
        // debugger;
        // dispatch(
        //   updateBid(
        //     props.id,
        //     taxTerm,
        //     "Weekly",
        //     // `${message}<br/>Bid Amount : ${bill}`,
        //     `${message}`,
        //     rate,
        //     //"In Progress",
        //     "New",
        //     date,
        //     sgFee,
        //     bill,
        //     "Bid Updated",
        //     "View Updated Bid",
        //     currency.substring(1)
        //   )
        // );
        dispatch(
          updateBid({
            id: props.id,
            // ActualRate: rate,
            ActualRate: bill,
            BudgetTypeId: rateBy === "Hour" ? 1 : rateBy === "Year" ? 4 : 2,
            Currency: currency.substring(1),
            IdealTermsName: taxTerm,
            Message: message,
            Modified: date,
            ProposalStatus: "New",
            // Rate: bill,
            Rate: rate,
            RecipientLabels: "View Updated Bid",
            SGFees: sgFee,
            SenderLabels: "Bid Updated",
          })
        );
      }
      dispatch(fetchInboxMessages("all_bids", "all_conversations", "null"));
      dispatch(fetchMessagesCounts("all_bids"));
      setTimeout(() => {
        dispatch(
          fetchInboxMessages("all_bids", "all_conversations", "null", 2, 5)
        );
      }, 1000);
    } else {
      saveBidToLocalStorage();
      // check if screensize is less than 768px push to /login
      if (window.innerWidth < 768||true) {
        history.push("/login");
      } else {
        dispatch(isModalVisibleAuth(true));
      }
    }
  };

  useEffect(() => {
    if (props?.jobOrder && props?.method == "put" || state?.ProposalId) {
      dispatch(fetchBidData(props?.jobOrder ?? state?.ProposalId));
    }
    setgigTitle(state?.title);
  }, []);
  useEffect(() => {
    if (props.method == "put") {
      if (dataSuccess?.Currency === "USD") {
        setCurrencySign("$USD");
      }
    }
  }, [dataSuccess?.ActualRate]);
  // useEffect(() => {
  //   debugger
  //   const { id ,title, FacilityName, RegionName, budget } =  state ;
  //   setgigTitle(state?.title);
  // }, []);

  useEffect(() => {
    if (props?.method === "put") {
      const msgs = dataSuccess?.MessageText?.split("<br/>");
      setMessage(msgs);
      // setMessage(dataSuccess[0].Message);
      // setRate(dataSuccess?.ActualRate);
      setRate(dataSuccess?.Rate);
      setTax(dataSuccess?.IdealTerms);
    }
  }, [dataSuccess?.Message, dataSuccess?.Rate]);
  useEffect(() => {
    //if redirectTo is set then createBid
    if (localStorage.getItem("redirectTo") === "/create-bid" && cookie) {
      const bidFromLocalStorage = JSON.parse(localStorage.getItem("bid"));
      const id = bidFromLocalStorage[0];
      const message = bidFromLocalStorage[4];

      const rateBy = bidFromLocalStorage[8];
      const rate = bidFromLocalStorage[10];
      const taxTerm = bidFromLocalStorage[11];
      const sgFee = bidFromLocalStorage[14];
      const bill = bidFromLocalStorage[18];
      const date = bidFromLocalStorage[13];

      setBill(bill);
      setMessage(message);
      setRateBy(rateBy);
      setRate(rate);
      setTax(taxTerm);
      setSGFEE(sgFee);
      if (bidshow) {
        dispatch(
          createBid(
            id,
            null,
            null,
            null,
            // `${message}<br/>Bid Amount: ${bill}`,
            `${message}`,
            date,
            date,
            null,
            rateBy,
            "In Progress",
            rate,
            taxTerm,
            0,
            date,
            sgFee,
            null,
            null,
            date,
            bill,
            currency.substring(1)
          )
        );
        setBidshow(false);
        dispatch(fetchInboxMessages("all_bids", "all_conversations", "null"));
        setTimeout(() => {
          dispatch(
            fetchInboxMessages("all_bids", "all_conversations", "null", 2, 5)
          );
        }, 1000);
      }
    }
  });
  useEffect(() => {
    setTax(dataSuccess?.IdealTermsName)
  }, [dataSuccess?.IdealTermsName]);

  useEffect(() => {
    if (success) {
      const stateLocal = JSON.parse(localStorage.getItem("bidData"));
      localStorage.removeItem("bidData");
      localStorage.removeItem("bid");
      localStorage.removeItem("redirectTo");
      dispatch(fetchMessagesCounts("all_bids"));
      dispatch(fetchInboxMessages("all_bids", "all_conversations", "null"));
      setTimeout(() => {
        dispatch(
          fetchInboxMessages("all_bids", "all_conversations", "null", 2, 5)
        );
      }, 1000);

      if (stateLocal) {
        const { id, title, FacilityName, RegionName, budget } = stateLocal;

        history.push({
          pathname: "/bidsuccess",
          state: {
            id: id,
            taxTerm,
            message,
            rate,
            rateBy,
            title,
            RegionName,
            budget,
            currency,
          },
        });
      } else if (state) {
        const { id, title, FacilityName, RegionName, budget, JobOrderId } = state;
        const RegionNames =
          dataSuccess?.gigs?.Cities?.CityName +
          " " +
          dataSuccess?.gigs?.Regions?.Code;
        history.push({
          pathname: "/bidsuccess",
          state: {
            id: dataSuccess?.JobOrderId ? dataSuccess?.JobOrderId : JobOrderId,
            taxTerm: taxTerm ? taxTerm : dataSuccess?.IdealTermsName,
            message: message ? message : dataSuccess?.Message,
            rate,
            rateBy,
            title,
            RegionName: Remote ? null : RegionName ? RegionName : RegionNames,
            budget,
            currency,
          },
        });
      }
    }

    if (upSuccess) {
      dispatch(fetchMessagesCounts("all_bids"));
      dispatch(fetchInboxMessages("all_bids", "all_conversations", "null"));
      setTimeout(() => {
        dispatch(
          fetchInboxMessages("all_bids", "all_conversations", "null", 2, 5)
        );
      }, 1000);
      const title = dataSuccess?.gigs?.Title;
      const RegionName =
        dataSuccess?.gigs?.Cities?.CityName +
        ", " +
        dataSuccess?.gigs?.Regions?.Code;
      const budget = dataSuccess?.gigs?.BudgetMin;
      history.push({
        pathname: "/bidsuccess",
        state: {
          id: dataSuccess?.JobOrderId,
          taxTerm: taxTerm ? taxTerm : dataSuccess?.IdealTermsName,
          message: message ? message : dataSuccess?.Message,
          rate,
          title,
          rateBy,
          Updated: "Updated",
          RegionName: Remote ? null : RegionName,
          budget,
        },
      });
      //   // title={dataSuccess.length ? dataSuccess[0].gigs[0].Title : title}
      //   // span1={dataSuccess.length ?  : RegionName}
      //   // span2={`$ ${dataSuccess.length ? dataSuccess[0].gigs[0].BudgetMin : budget?budget:''} /wk`}
    }
  });
  const getMessage = (e) => {
    setMessage(e.target.value);
    setMessageError(null);
  };

  const selectValue = (e) => {
    setTax(e.target.value);
    setTaxTermError(null);
    if (taxTerm) {
      calculateBill();
    }
  };

  const currencyHandle = async (e) => {
    const convertedCurrency = await Convert(e)
      .from(currency.slice(1))
      .to("USD");
    setDollar(convertedCurrency.toFixed(2));
  };
  const billToDollar = async (e) => {
    const convertedCurrency = await Convert(e)
      .from(currency.slice(1))
      .to("USD");
    setDollarBill(convertedCurrency.toFixed(2));
  };
  const handleInput = (e) => {
    if (!onlyNumb.test(e.target.value)) {
      setBidInputError("Not a valid number");
    }
    if (talentPrivate) {
      const inputValue = e.target.value;
      if (e?.nativeEvent?.inputType == 'deleteContentBackward') {
        setRate(inputValue?.toString());
      } else {
        setRate(parseFloat(inputValue)?.toFixed(2));
      }
      setTimeout(() => {
        const numberStr = String(inputValue)?.replace(/\.0+$/, "");
        console.log(inputRef.current.setSelectionRange, "numberStr", inputRef.current.selectionEnd, inputRef.current.selectionStart)
        inputRef.current.setSelectionRange(numberStr?.length, numberStr?.length)
      }, 50)
    } else if (!talentPrivate){
      setRate(e.target.value)
      setError("");
      currencyHandle(e.target.value);
    } else if (taxTerm == "") {
      setTaxTermError("Choose a tax term");
    }
    if (e.target.value < 5) {
      setBidInputError("Minimum pay package is $5");
    } else if (e.target.value > 4) {
      setBidInputError("");
    }

    // check ti
  };
  useEffect(() => {
    if (rate && onlyNumb.test(+rate)) {
      calculateBill();
    }
    if (taxTerm == "W-2") {
      setSGFEE(29);
    }
    if (taxTerm == "1099/Corp-to-Corp") {
      setSGFEE(23.5);
    }
  });

  // Billed to client Formula
  // BilledToClient = ProposalBidRate / ((100 - SGFees)/100);
  // SGFees would be 23.5 if Talent selected 1099
  // SGFees would be 29 if Talent selceted W2
  //NEW FORMULA FOR BILL RATE GIVEN BY BRAD HILL
  // FOR BOTH W2 and 1099 ProposalBidRate * 1.49339
  const calculateBill = () => {
    // if (taxTerm == "W-2" || dataSuccess?.IdealTermsName == "W-2") {
    //   var billedd = rate / ((100 - 29) / 100);
    //   let bill = parseFloat(billedd).toFixed(2);
    //   setBill(bill);
    //   billToDollar(bill);
    // } else if (
    //   taxTerm == "1099/Corp-to-Corp" ||
    //   dataSuccess?.IdealTermsName == "1099/Corp-to-Corp"
    // ) {
    //   // setSGFEE(23.5);
    //   let billed = rate / ((100 - 23.5) / 100);
    //   let bill = parseFloat(billed).toFixed(2);
    //   setBill(bill);
    //   billToDollar(bill);
    // }
    if ((state?.AccountRole === 'No billing' || dataSuccess?.gigs?.AccountRole === 'No billing' || state?.JobType === "Permanent")){
      setBill(+rate);
      billToDollar(+rate);
    }
    // else{
    //   const originalAmount = +rate;
    //   var billedd = +rate * 1.49339;
    //   if ((taxTerm == "W-2") && ProfessionCode != "HC") {
    //     billedd = calculateFinalAmount(originalAmount, skillgigsFeeRate, payrollFeeRate);
    //   }
    //   let bill = parseFloat(billedd).toFixed(2);
    //   setBill(bill);
    //   billToDollar(bill);
    // }
  };

  useEffect(() => {
    if ((state?.AccountRole !== 'No billing' || dataSuccess?.gigs?.AccountRole !== 'No billing') && (state?.JobType != "Permanent" || dataSuccess?.gigs?.JobType != "Permanent")) {
      if ((taxTerm == "W-2" || taxTerm == "1099/Corp-to-Corp") && ProfessionCode != "HC") {
        if (taxTerm == "1099/Corp-to-Corp"){
          payrollFeeRate = 5;
        }else{
          payrollFeeRate = 18;
        }
        setBill(calculateFinalAmount(+rate, skillgigsFeeRate, payrollFeeRate)?.toFixed(2))
      } else {
        setBill(parseFloat(+rate * 1.49339).toFixed(2));
        billToDollar(parseFloat(+rate * 1.49339).toFixed(2));
      }
    }
  }, [+rate, taxTerm])

  // useEffect(() => {
  //   if (dataSuccess.length) {
  //     try {
  //       const msgs = dataSuccess[0].Message.split('<br/>');
  //       setMessage(msgs[0]);
  //     }
  //     catch {
  //
  //     }
  //   }
  // }, [])

  useEffect(() => {
    setRateBy(dataSuccess?.BudgetType ? dataSuccess?.BudgetType : "Hour")
  }, [dataSuccess?.BudgetType])

  const handleRateBy = (e) => {

    setRateBy(e.target.value);

    if (rate < 5 || rate == "" || rate == null) {
      setBidInputError("Minimum bid amount is $5");
      return;
    } else {
      setBidInputError("");
    }
  };

  const [form] = Form.useForm();

  const Message = (message) => {
    return message.split("<br/>")[0];
  };

  useEffect(() => {
    return () => {
      dispatch(updateBidReset());
    };
  }, []);

  return (
    <div style={{ backgroundColor: "#f8f8f8" }}>
      <Row>
        <Col lg={14} md={24} xs={24} sm={24}>
          <div className="site-card-border-less-wrapper">
            <Card className="bid-card" lg={22} md={22} xs={22} sm={22}>
              {/* <Col sm={24}> */}
              <p className="bid-heading">
                {props.method === "put" ? (
                  <div className="EditBid-ToolTips">
                    {" "}
                    Edit bid{" "}
                    <ToolTips
                      placement="bottomRight"
                      title="Need to edit your placed bid? You can update your bid to make changes."
                    />
                  </div>
                ) : (
                  "Place your bid for the gig"
                )}
              </p>
              <>
                <div
                  className="bid-image-title"
                  onClick={() => history.goBack()}
                >
                  {placeholder ? (
                    <img
                      width={150}
                      height={100}
                      alt="bid"
                      src={Defaultplaceholder ? getImageByStateCode() : getImageByStateCode(
                        RegionCodeImg
                      )}
                      className="bid-image"
                      onError={(e) => setDefaultplaceholder(true)}
                    />
                  ) : (
                      <img
                        width={150}
                        height={100}
                        src={
                          localStorage.getItem("image")
                            ? localStorage.getItem("image")
                            : getImageByStateCode(
                              RegionCodeImg,
                              CityNameImg
                            )
                        }
                      alt="bid"
                      // src={getImageByStateCode(
                      //   RegionName?.Location.split(", ")[1],
                      //   RegionName?.Location.split(", ")[0]
                      // )}
                      className="bid-image"
                      onError={(e) => showPlaceholder(true)}
                    />
                  )}

                  <span className="bid-job-title">
                    {" "}
                    {gigTitle ? gigTitle : dataSuccess?.gigs?.Title}{" "}
                  </span>
                </div>

                <p className="bid-text">
                  Place a bid to see if you'll be a great fit for the gig.
                </p>
              </>

              {loading || upLoading || dataLoading || rLoading ? (
                <div className={"bids-spin"}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Form
                    form={form}
                    className="form-bid"
                    method={props.method}
                    onFinish={(e) => handleSubmit(e)}
                  >
                    {Error || upError || rError ? (
                      <>
                        <Alert
                          style={{ marginBottom: "10px" }}
                          message="Error"
                          description="Oh snap! something went wrong."
                          type="error"
                          showIcon
                        />
                        {Error || upError || rError}
                      </>
                    ) : (
                      <label></label>
                    )}
                    <Form.Item
                      validateStatus={messageError ? "error" : ""}
                      help={messageError ? messageError : null}
                      name="coverLetters"
                      className="coverLetterTextarea"
                      onChange={getMessage}
                    >
                      <TextArea
                        showCount
                        maxLength={150}
                        className="coverLetterTextarea"
                        rows={6}
                        name="coverLetters"
                        defaultValue={
                          props.method === "put" ? dataSuccess?.Message : ""
                        }
                        onChange={(e) => {
                          // if (e.target.value.length < 200)  .slice(0, 150)
                          setMessage(e.target.value);
                        }}
                        placeholder="You can mention how you'll be a great fit for the gig..."
                        value={message}
                      />
                    </Form.Item>

                    <h4 className="billed-client tax-text">
                      Choose your tax term
                    </h4>

                    <Form.Item
                      name="term"
                      style={{ width: "100%", marginBottom: "1.25rem" }}
                      validateStatus={taxTermError ? "error" : null}
                      help={taxTermError ? taxTermError : null}
                      // required={true}
                    >
                      <Radio.Group
                        onChange={selectValue}
                        value={taxTerm}
                        name="taxTerm"
                        // initialValue={taxTerm}
                        defaultValue={
                          props.method === "put"
                            ? dataSuccess?.IdealTermsName
                            : ""
                        }
                        className="radio-group"
                      >
                        <Radio
                          value="W-2"
                          className="radio-styles"
                          style={
                            taxTermError ? { border: " solid 1px #db2828" } : {}
                          }
                        >
                          W-2
                        </Radio>
                        <Radio
                          className="radio-styles"
                          value="1099/Corp-to-Corp"
                          style={
                            taxTermError ? { border: " solid 1px #db2828" } : {}
                          }
                        >
                          1099/Corp-to-Corp
                        </Radio>
                      </Radio.Group>
                    </Form.Item>

                    <h4 className="billed-client">
                      What is your desired pay?{" "}
                      <ToolTips
                        placement="bottomRight"
                        title="This is your chance to name your desired pay. The estimated total billed changes according to your desired pay."
                      />{" "}
                    </h4>
                    <div className="rate-group">
                      <Form.Item name="termrates">
                        <Radio.Group
                          onChange={handleRateBy}
                          className="radio-rate-group"
                          name="rateBy"
                          defaultValue={
                            props.method === "put"
                              ? dataSuccess?.BudgetType
                              : rateBy
                          }
                          value={rateBy}
                        >
                            <Radio className="radio-styles-rate" value="Hour">
                              Hourly
                            </Radio>
                            <Radio className="radio-styles-rate" value={ProfessionCode === "IT" && JobType === "Permanent" ? "Year" : "Week"}>
                              {ProfessionCode === "IT" && JobType === "Permanent" ? "Yearly" : "Weekly"}
                            </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        className="rate-field1"
                        required={true}
                        // validateStatus={bidInputError ? "error" : null}
                      >
                        <div className="select-currency">
                          {/* <Select
                              defaultValue={
                                props.method === "put"
                                  ? dataSuccess?.Currency === "USD"
                                    ? "$USD"
                                    : dataSuccess?.Currency === "INR"
                                    ? "₹INR"
                                    : dataSuccess?.Currency === "PHP"
                                    ? "₱PESO"
                                    : "$CAD"
                                  : "$USD"
                              }
                              onChange={handleChange}
                            >
                              <Option
                                className="select-currency-options"
                                value="$USD"
                              >
                                USD - $
                              </Option>
                              <Option
                                className="select-currency-options"
                                value="₹INR"
                              >
                                INR - ₹
                              </Option>
                              <Option
                                className="select-currency-options"
                                value="₱PHP"
                              >
                                PESO - ₱
                              </Option>
                              <Option
                                className="select-currency-options"
                                value="$CAD"
                              >
                                CAD - $
                              </Option>
                            </Select> */}

                          <Row>
                            <Input
                              // placeholder={currency.substring(0,1)}
                              ref={inputRef}
                              prefix={currency.substring(0, 1)}
                              name="termrate"
                              onChange={handleInput}
                              className="rate-field"
                              style={bidInputError ? { border:'1px solid #db2828' } : {}}
                              //defaultValue={dataSuccess?.Rate}
                              value={rate}
                            />
                          </Row>
                        </div>
                        {currency.slice(1) !== "USD" ? (
                          !dollarClick ? (
                            <div
                              className="convert-to-usd"
                              onClick={() => {
                                rate
                                  ? setDollarClick(true)
                                  : setDollarClick(false);
                              }}
                            >
                              Convert to USD
                            </div>
                          ) : dollar ? (
                            <div className="convert-to-usd conversion-pointer">
                              Convert to USD ${dollar}
                            </div>
                          ) : (
                            <Loader height=".5vh" />
                          )
                        ) : null}
                      </Form.Item>
                    </div>
                    <div className="rate-spans">
                      <Form.Item
                        validateStatus={bidInputError ? "error" : null}
                        help={bidInputError ? bidInputError : null}
                      >
                        <span
                          className="rate-span"
                          // style={bidInputError ? { color: "#db2828" } : {}}
                        >
                          Note:{span}
                        </span>
                      </Form.Item>
                    </div>
                    {error ? (
                      <label
                        style={{
                          color: "red",
                          marginLeft: "2px",
                          marginTop: "2px",
                        }}
                      >
                        {error}
                      </label>
                    ) : null}
                   { !talentPrivate && <Form.Item>
                      <h4
                        className="billed-client"
                        style={
                          window.innerWidth > 756 ? { marginTop: "1rem" } : {}
                        }
                      >
                        Estimated total billed to employer
                      </h4>
                      {/* <div className="grid-amount"> */}
                      <Row>
                        <Col lg={12} md={12} sm={24} xs={24}>
                          {bill ? (
                            <Input
                              prefix={currency.substring(0, 1)}
                              className="bill-amount"
                              disabled
                              value={bill}
                              // defaultValue={dataSuccess?.ActualRate}
                            />
                          ) : props?.method === "put" ? (
                            <Input
                              className="bill-amount"
                              prefix={currency.substring(0, 1)}
                              disabled
                              defaultValue={dataSuccess?.ActualRate}
                            />
                          ) : (
                            <Input
                              className="bill-amount"
                              prefix={currency.substring(0, 1)}
                              disabled
                              value={"0.00"}
                            />
                          )}
                        </Col>
                        {/* </div> */}
                      </Row>
                    </Form.Item>}
                    {
                      currency.slice(1) !== "USD" ? (
                        !dollarBillClick ? (
                          <div
                            className="convert-bill-to-usd"
                            onClick={() => {
                              bill
                                ? setDollarBillClick(true)
                                : setDollarBillClick(false);
                            }}
                          >
                            Convert to USD
                          </div>
                        ) : dollarBill ? (
                          <>
                            <div className="convert-bill-to-usd conversion-pointer">
                              Convert to USD ${dollarBill}
                            </div>{" "}
                          </>
                        ) : (
                          <Loader height=".5vh" />
                        )
                      ) : null
                      // <div className="convert-bill-to-usd">{dollarBill}</div>
                    }
                    <Divider className="bid-divider" />
                    <div className="bid-btn-div">
                      {/* <Row justify="end">
                  <Col span={6}> */}
                      <Form.Item>
                        <Button
                          type="default"
                          htmlType="submit"
                          className="bid-btn-form"
                        >
                          {props.method === "put" ? "Update bid" : "Place bid"}{" "}
                          {bill && !talentPrivate
                            ? `(${currency.substring(0, 1)} ${bill})`
                            : props.method === "put" && !talentPrivate
                            ? `(${dataSuccess?.ActualRate})`
                            : ""}
                        </Button>
                      </Form.Item>
                      {/* </Col>
                </Row> */}
                    </div>
                  </Form>
                </>
              )}
              {/* </Col> */}
            </Card>
          </div>{" "}
        </Col>
      </Row>
    </div>
  );
};
export default BidForm;