import React from "react";
import "./BackNextSignUpButton.css";
import Licenseback from "../../../../../assets/icons/Licenseback.svg"
import { Button, Col, Row } from "antd";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const BackNextSignUpButton = ({
  backFunction,
  nextFunction,
  nextBtnLoading,
  skipFunction,
  skip,
}) => {
  const {pathname} = useLocation();

  
  return (

    <div className="BottomButtonsParentContainer">
      <div className="buttonsRowContainer">
        <Row className={"Btns-row2"}>
          
          <div className="gap-back-next-signup-buttons">
        {window.innerWidth > 768 && (
            <Col lg={11} xs={24} md={10} className={"Back-btn-div"}>
              <Button className="Back-btn" onClick={backFunction}>
                Back
              </Button>
            </Col>
          )}
          {
            window.innerWidth > 768 && (
              <Col lg={11} xs={24} md={10} className={"Next-btn-div"}>
                <Button className="Next-btn" onClick={nextFunction} loading={nextBtnLoading}>
                  Next
                </Button>
              </Col>
            )
          }
          {
            window.innerWidth > 768 && pathname != '/resume-upload-v2' ? (
              <Col lg={11} xs={24} md={10} className={"Next-btn-div"} >
                <Button className="Skip-btn" onClick={skipFunction} >
                  Skip
                </Button>
              </Col>
            ) :""
          }
        
    </div>


          {window.innerWidth <= 768 ?
            <div className="main-div-mobile1">
              <div className="button-mob-view1">
                <button onClick={backFunction} className="back-btn-mobile1">
                  <img src={Licenseback} />
                </button>
                {skip && pathname != '/resume-upload-v2' ? (
                  <button className="skip-btn-mobile1" onClick={skipFunction}>
                    Skip
                  </button>
                ) : ""}
                <button
                  onClick={nextFunction}
                  className="next-btn-mobile1"
                  style={{width : pathname === '/resume-upload-v2' ? '70%':''}}
                >
                  <span className="btn-read-rules1">Next</span>
                </button>
              </div>
            </div> : ""
          }
        </Row>
      </div>
    </div>
  );
};

export default BackNextSignUpButton;
