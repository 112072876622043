import React, { useState } from "react";
import "./DropdownSelection.css";
import { AutoComplete, Input, Select, Spin } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useEffect } from "react";
import { debounce } from "../../../../shared/helpers";
function DropdownSelection({ title, type, placeholder, onChange, value, options, keyVal, selected, setSelected, error ,loading }) {
  const [dataSource, setdataSource] = useState([]);
  const [isSuggestionsVisible, setSuggestionsVisible] = useState(false);
  useEffect(() => {
    const temp = options.map((option) => ({ value: option[keyVal] ?? option, key:option['_id'] ?? option[keyVal] ?? option }));
    setdataSource(temp);
  }, [options])
  
  const handleSelect = (selectedValue) => {
    const selectedObject = options.find(
      (option) => option[keyVal] === selectedValue
    );
    setSelected(selectedObject);
    setSuggestionsVisible(false);
  };
  return (
    <div className="TextInputParentContainer">
      <div className="textInputContainer">
        <div className="TextInputTitleContainer">
          <div className="TextInputTitle-dropdown">{title}</div>
          <div className="TextInputType-dropdown">({type})</div>
        </div>
        <div className="TextSubTitle">Type and pick a discipline</div>
        <AutoComplete
          options={dataSource}
          value={value}
          onChange={(value) => {
            onChange(value);
            setSuggestionsVisible(true);
          }}
          onFocus={()=>value?.length>0 && !selected?setSuggestionsVisible(true):setSuggestionsVisible(false)}
          onSelect={handleSelect}
          open={isSuggestionsVisible}
          status={error ? "error" : ''}
          style={{ width: "100%" }}
          className="TextInputStyleDropDownAutoComplete"
          notFoundContent={
            !loading && dataSource.length < 1 ? "No Data" : <><Spin size="small" /></>
          }
        >
          <Input
            placeholder={placeholder}
            className="TextInputStyleDropDown"
            status={error ? "error" : ''}
            onFocus={() => {
              if (options.length > 0) {
                setSuggestionsVisible(true);
              }
            }}
            onBlur={() => setSuggestionsVisible(false)}
            suffix={
            <span >
              {loading ? <Spin size="small" /> : null}
            </span>
            }
          />
        </AutoComplete>
        {error && 
          <div className="errorMsg">
          <WarningFilled className="errorIcon"/><span className="errorMsg-content">{error}</span>
          </div>
        }
      </div>
    </div>
  );
}
export default DropdownSelection;
