import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import TalentEmployer from "../pages/WelcomeWizard/TalentEmployer";
import Profession from "../pages/WelcomeWizard/Profession";
import RoleExperience from "../pages/WelcomeWizard/RoleExperience";
import SuccessPage from "../pages/WelcomeWizard/SuccessPage";
import ResumeUpload from "../pages/WelcomeWizard/ResumeUpload";

import Credentialing from "../pages/Credentialing/Credentialing2";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";
import DashBoard from "../pages/DashBoard/DashBoard";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import LinkedInCheck from "../pages/Auth/components/LinkedInCheck";
import SearchResults from "../pages/SearchGigs/SearchResults";
import TermsOfServices from "../pages/Auth/components/TermsOfServices";

import ReceivedBidDetailPage from "../pages/Bid/ReceivedBids/ReceivedBidDetailPage";
import PlacedBidsDetailPage from "../pages/Bid/PlaceBids/PlaceBidsDetailPage";
import Proposals from "../pages/Proposals/Proposals";
import EditBid from "../pages/Bid/components/EditBid";
import BidtoApply from "../pages/Bid/components/BidToApply";
import Messages from "../pages/Messages/Messages";
import Success from "../pages/Bid/components/successBid";
import Chat from "../pages/Chat/Chat";
import ChatHistory from "../pages/Chat/components/ChatHistory";
import ReceivedBids from "../pages/Bid/ReceivedBids/ReceviedBids2";
import ManageSkillListing from "../pages/Manage_SkillListing/ManageSkillListingOne";
import SkillListng from "../pages/SkillListing/SkillListingPage";
import DeclineBids from "../pages/Bid/DeclineBids";
import GuestGidDetailProvider from "../pages/SearchGigs/GuestUser/components/GuestGidDetailProvider";
import TimeSheet from "../pages/TimeSheet/TimeSheet";
import TimeCorrection from "../pages/TimeSheet/TimeCorrection/TimeCorrection";

import Header from "../components/Header/Header";
import NewCreateSkillListing from "../pages/SignUp/NewCreateSkillListing/CreateSkillListing";
import history from "../config/history";
import SearchGigs from "../pages/SearchGigs/SearchGigs";
import Profile from "../pages/Profile/Profile";
import LinkSocial from "../pages/Profile/components/LinkSocial";
import Loader from "../components/Loader/Loader";
import JobDetailsPageProvider from "../pages/DashBoard/components/JobDetailsPageProvider";
import DummyContent from "../pages/DashBoard/components/DummyContent";
import MyFavorites from "../pages/MyFavorites/MyFavorites";
import RetractedBid from "../pages/Bid/components/RetractedBid";
import { retractBid, userLanded } from "../redux/actions";
import PlaceBids from "../pages/Bid/PlaceBids/PlaceBids";
import Interview from "../pages/Interview/Interview";
import _3D_Resume from "../pages/_3D_Resume/_3D_Resume";
import HomeSearchResults from "../pages/Home/HomeSearchResults";
import HomeSeeAllpage from "../pages/Home/HomeSeeAllpage";
import LicenseNumber from "../components/license/LicenseNumber";
import BasicLifeSupport from "../components/LifeSupport/BasicLifeSupport";
import {
  LoginRoute,
  ProtectedRoute,
  PublicRoute,
  RestrictSignUpRoutes,
  SpecialRoute,
  ValidateRoute,
} from "./routes";
import MessageDetail from "../pages/Messages/components/MessageDetailBid/MessageDetail";
import Footer from "../components/Footer";
import RoleExperienceNew from "../pages/WelcomeWizard/RoleExperienceNew";
import { RatingForm } from "../pages/Profile/components/RatingReviews/RatingForm";
import RatingformTwo from "../pages/Profile/components/RatingReviews/RatingformTwo";
import AllCard from "../pages/Profile/components/RatingReviews/Ratingcards/AllCard";
import ManageAssignment from "../pages/Manage_Assignments/ManageAssignment";
import Conversation from "../pages/Conversations/Conversations";
import ScrollToTop from "../components/ScrollToTop";
import NotificationPage from "../pages/NotificationScreen";
import EmailVerification from "../components/EmailVerification/EmailVerification";

import ViewListing from "../pages/ViewListing/ViewListing";
import SkillListingDetailPage from "../pages/Manage_SkillListing/SkillListingDetailPage/SkillListingDetailPage";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import Error from "../pages/Error/Error";
import Dashboard from "../pages/NewDashboard/Dashboard";
import axios from "axios";
// import Dashboard from "../pages/DashBoard/DashBoard";
import ReadRules from "../pages/WelcomeWizard/ReadRules";
import SuccessProfileInfo from "../pages/WelcomeWizard/SuccessProfileInfo";
import EffectiveProfileInfo from "../pages/WelcomeWizard/EffectiveProfileInfo";
import NameScreen from "../pages/SignUp/NameScreen";
import LocationScreen from "../pages/SignUp/LocationScreen";
import DisciplineScreen from "../pages/SignUp/Discipline";
import SpecialityScreen from "../pages/SignUp/Speciality";
import MobileNumberScreen from "../pages/SignUp/MobileNumber";
import ExperienceLevelScreen from "../pages/SignUp/ExperienceLevel";
import WorkPlacePolicyScreen from "../pages/SignUp/WorkPlacePolicy";
import WorkAuthorization from "../pages/SignUp/WorkAuthorization";
import LicenseScreen from "../pages/SignUp/License";
import VeteranScreen from "../pages/SignUp/Veteran";
import ACLS_DocV2 from "../pages/SignUp/Components/DocUpload/ACLS_DocV2";
import BLSDocv2 from "../pages/SignUp/Components/DocUpload/BLSDocv2";
import Driver_license_DocV2 from "../pages/SignUp/Components/DocUpload/Driver_license_DocV2";
import Reference1_DocV2 from "../pages/SignUp/Components/DocUpload/Reference1_DocV2";
import Reference2_DocV2 from "../pages/SignUp/Components/DocUpload/Reference2_DocV2";
import Social_security_Doc from "../pages/SignUp/Components/DocUpload/Social_security_Doc";
import StateLisenceV2 from "../pages/SignUp/Components/DocUpload/StateLisenceV2";
import ResumeUploadv2 from "../pages/SignUp/Components/DocUpload/ResumeUploadv2";
import AnyCareerGap from "../pages/SignUp/Components/DocUpload/Signup_gap_screen/AnyCareerGap";
import EducationalScreenOne from "../pages/NewSignUP/EducationalScreenOne";
import EducationalScreenTwo from "../pages/NewSignUP/EducationalScreenTwo";
import EducationalScreenThree from "../pages/NewSignUP/EducationalScreenThree";
import SuccessPageSignUp from "../pages/NewSignUP/SuccessScreen";
import ViewCompanyJobs from "../pages/CompanyProfile/ViewCompanyJobs";
import ViewResume from "../pages/_3D_Resume/ViewResume/ViewResume";
import { ArraySignUpRoute } from "../shared/helpers";
import EmployerLandingPage from "../pages/EmployerLandingPage/EmployerLandingPage";
import NewSignUp from "../pages/NewSignupPage/NewSignup";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { APP_NAME } from "../constants/userTrackingConstants";
import SignUpLogin from "../pages/Auth/components/SignUpLogin";
import SocialSecurityNumber from './../pages/SignUp/Components/SocialSecurityNumber/SocialSecurityNumber';
import LicenseNumberPage from "../components/LicenseNumberComponent/LicenseNumberPage";
import AdvancedLifeSupport from "../components/AdvancedLifeSupport/AdvancedLifeSupport";
import DriversLicense from "../components/Driver'sLicense/DriversLicense";
import MobileNumberOTP from "../pages/SignUp/Components/MobileNumberOTP/MobileNumberOTP";


export const AppRouter = (props) => {
  const dispatch = useDispatch()
  const emailVerified = props.isEmailVerified;
  const { id } = props.userProfile;
  const { WizardFlow } = props.userProfile.profile;
  const [ipaddress, setIpaddress] = useState("");

  const getIpAddress = async () => {
    const responce = await axios.get("https://api.ipify.org?format=json");
    setIpaddress(responce?.data?.ip);
  };

  const locationUrl = useLocation();
  const currentUrl = locationUrl?.pathname;
  const initialPath = locationUrl?.pathname.split("/")[1];

  const DEFAULT_USER_LANDING_TRACK = {
    page:'/',event:'USER_LANDED',step:null, appName:APP_NAME.TALENT, url: window.location.href
  }

  useEffect(() => {
    getIpAddress();
    dispatch(userLanded({...DEFAULT_USER_LANDING_TRACK,uuid: localStorage.getItem("uuid") || uuid()}))
  }, []);

  const { location, width } = props;

  // //

  return (
    // <Router  basename={'/app'}>
    <>
      <ScrollToTop />
      {/* <Suspense fallback={<Loader />}> */}
      <div className="content-wrap">
        <div
          className={`headerSpaceRouter ${
            ArraySignUpRoute?.includes(currentUrl) === true
              ? "headerSpaceRouterPadding"
              : null
          } ${
            initialPath == "employer" && !props.authToken
              ? "less-padding-for-landing-page"
              : ""
          }`}
        >
          <Header
            changeStage={props.shareStage}
            stage={props.stage}
            id={id}
            emailVerified={emailVerified}
            WizardFlow={WizardFlow}
            EmailVerification={EmailVerification}
          />
          <Switch>
            {/* <PublicRoute exact path="/">
            <Home />
          </PublicRoute> */}
            <ValidateRoute exact path="/">
              <Home />
            </ValidateRoute>
            <PublicRoute exact path="/auth">
              <AuthSuccess IPAddress={ipaddress} />
            </PublicRoute>

            <LoginRoute path="/login">
              <SignUpLogin isStatic={true} isSignup={false} />
              {/* <Auth /> */}
            </LoginRoute>
            <LoginRoute path="/signup">
              <SignUpLogin isStatic={true} isSignup={true}/>
              {/* <Auth /> */}
            </LoginRoute>
            <LoginRoute path="/joinus">
              <SignUpLogin isStatic={true} isSignup={true}/>
            </LoginRoute>
            <LoginRoute path="/signin">
              <SignUpLogin isStatic={true} isSignup={false} />
            </LoginRoute>
            <PublicRoute exact path="/linkcheck">
              <LinkedInCheck />
            </PublicRoute>
            <PublicRoute exact path="/search/Pvt">
              <SearchGigs isPrivatePage={true} />
            </PublicRoute>
            <PublicRoute exact path="/Private">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/search/:searchValue">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/details/:jobid">
              <GuestGidDetailProvider />
            </PublicRoute>
            <PublicRoute path="/create-bid">
              <BidtoApply />
            </PublicRoute>
            <PublicRoute path="/error">
              <Error />
            </PublicRoute>
            <ProtectedRoute path="/receivedBids">
              <ReceivedBids
                emailVerified={emailVerified}
                id={id}
                WizardFlow={WizardFlow}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/receivedbid-detail/:bidId"
              component={ReceivedBidDetailPage}
            />
            <ProtectedRoute path="/placebids">
              <PlaceBids
                emailVerified={emailVerified}
                id={id}
                WizardFlow={WizardFlow}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/placedbid-detail/:bidId"
              component={PlacedBidsDetailPage}
            />
            <ProtectedRoute path="/name">
              <RestrictSignUpRoutes>
                <NameScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/location">
              <RestrictSignUpRoutes>
                <LocationScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/discipline">
              <RestrictSignUpRoutes>
                <DisciplineScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/speciality">
              <RestrictSignUpRoutes>
                <SpecialityScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/mobileno">
              <RestrictSignUpRoutes>
                <MobileNumberScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/mobileOtp">
              <RestrictSignUpRoutes>
                <MobileNumberOTP/>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/explever">
              <RestrictSignUpRoutes>
                <ExperienceLevelScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/workPlacePolicy">
              <RestrictSignUpRoutes>
                <WorkPlacePolicyScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/workAuthorization">
              <RestrictSignUpRoutes>
                <WorkAuthorization />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/license">
              <RestrictSignUpRoutes>
                <LicenseScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/veteran">
              <RestrictSignUpRoutes>
                <VeteranScreen />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/talentemployer">
              <RestrictSignUpRoutes>
                <TalentEmployer />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/profession">
              <RestrictSignUpRoutes>
                <Profession />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/read-rules">
              <RestrictSignUpRoutes>
                <ReadRules />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/profile-instruction">
              <RestrictSignUpRoutes>
                <SuccessProfileInfo />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/effective-profile-info">
              <RestrictSignUpRoutes>
                <EffectiveProfileInfo />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload-v2">
              {/* <RestrictSignUpRoutes> */}
                <ResumeUploadv2 />
              {/* </RestrictSignUpRoutes> */}
            </ProtectedRoute>
            <ProtectedRoute exact path="/any-career-gap">
              <RestrictSignUpRoutes>
                <AnyCareerGap />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/state-lisence-doc">
              <RestrictSignUpRoutes>
                <StateLisenceV2 />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/bls-doc">
              <RestrictSignUpRoutes>
                <BLSDocv2 />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/acls-doc">
              <RestrictSignUpRoutes>
                <ACLS_DocV2 />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            {/* <ProtectedRoute path="/social-security-doc">
              <RestrictSignUpRoutes>
                <Social_security_Doc />
              </RestrictSignUpRoutes>
            </ProtectedRoute> */}
            <ProtectedRoute path="/social-security-num">
              <RestrictSignUpRoutes>
                <SocialSecurityNumber />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/driver-license-doc">
              <RestrictSignUpRoutes>
                <Driver_license_DocV2 />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/reference1-doc">
              <RestrictSignUpRoutes>
                <Reference1_DocV2 />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/reference2-doc">
              <RestrictSignUpRoutes>
                <Reference2_DocV2 />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/profile-instruction">
              <RestrictSignUpRoutes>
                <SuccessProfileInfo />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/effective-profile-info">
              <RestrictSignUpRoutes>
                <EffectiveProfileInfo />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/basic-profile-success">
              <RestrictSignUpRoutes>
                <SuccessPageSignUp />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/linksocial" component={LinkSocial} />
            <ProtectedRoute
              path="/create-skilllisting"
              component={SkillListng}
            />
            <ProtectedRoute path="/create-skilllisting-signup">
              <RestrictSignUpRoutes>
                <SkillListng />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute exact path="/role-experience">
              <RestrictSignUpRoutes>
                <RoleExperienceNew />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/credentialing" component={Credentialing} />
            <ProtectedRoute path="/manageskilllisting">
              <ManageSkillListing />
            </ProtectedRoute>
            <ProtectedRoute path="/skill-listing-detail-page/:skillId">
              <SkillListingDetailPage />
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing/:id">
              <ViewListing />
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing">
              <ViewListing />
            </ProtectedRoute>
            <ProtectedRoute exact path="/company-jobs/:id">
              <ViewCompanyJobs />
            </ProtectedRoute>
            <PublicRoute path="/company-profile/:id">
              <CompanyProfile />
            </PublicRoute>
            {/* <ProtectedRoute path="/company-profile/:id">
              <CompanyProfile />
            </ProtectedRoute> */}
            <ProtectedRoute path="/dummy">
              <DummyContent />
            </ProtectedRoute>
            <ProtectedRoute path="/myfavorites">
              <MyFavorites />
            </ProtectedRoute>
            <ProtectedRoute path="/dashboard">
              {/* <DashBoard
                shareStage={props.shareStage}
                emailVerified={emailVerified}
              /> */}
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute path={"/proposals"}>
              <Proposals />
            </ProtectedRoute>
            <ProtectedRoute path="/bidsuccess">
              <Success />
            </ProtectedRoute>
            <ProtectedRoute path="/edit-bid/:id">
              <EditBid />
            </ProtectedRoute>
            <ProtectedRoute exact path="/messages/:id?">
              <Conversation />
            </ProtectedRoute>
            <ProtectedRoute exact path="/chats">
              <Conversation />
            </ProtectedRoute>
            <ProtectedRoute exact path="/notifications">
              <NotificationPage />
            </ProtectedRoute>

            {
              // commenting this out as it is a dublicate route for another component if it is not useable please remove.
              /* <ProtectedRoute path="/messages/:id">
              {window.innerWidth < 768 ? <MessageDetail /> : <Messages />}
            </ProtectedRoute> */
            }
            <ProtectedRoute
              path="/sign_educational_screen1"
              component={EducationalScreenOne}
            />
            <ProtectedRoute path="/sign-educational-screen2">
              <RestrictSignUpRoutes>
                <EducationalScreenTwo />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/sign_educational_screen3">
              <RestrictSignUpRoutes>
                <EducationalScreenThree />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/chat/history">
              <ChatHistory />
            </ProtectedRoute>
            <ProtectedRoute path="/DeclineBids">
              <DeclineBids />
            </ProtectedRoute>
            <ProtectedRoute exact path="/timesheet">
              <TimeSheet />
            </ProtectedRoute>
            <ProtectedRoute exact path="/profile">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute exact path="/requestcorrection">
              <TimeCorrection />
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload">
              <RestrictSignUpRoutes>
                <ResumeUpload />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/interview">
              <Interview />
            </ProtectedRoute>
            <ProtectedRoute path="/3d_resume">
              <_3D_Resume />
            </ProtectedRoute>
            <PublicRoute path="/resume/:id">
              <ViewResume />
            </PublicRoute>
            <ProtectedRoute path="/RetractedBids">
              <RetractedBid />
            </ProtectedRoute>
            <ProtectedRoute path="/HomeResult">
              <HomeSearchResults />
            </ProtectedRoute>
            <ProtectedRoute path="/homeSeeAll">
              <HomeSeeAllpage />
            </ProtectedRoute>
            <ProtectedRoute path="/manage_assignments">
              <ManageAssignment />
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form">
              <RatingForm />
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form2">
              <RatingformTwo />
            </ProtectedRoute>
            <ProtectedRoute path="/all_rate_card">
              <AllCard />
            </ProtectedRoute>
            <SpecialRoute path="/forgot">
              <ForgetPassword />
            </SpecialRoute>
            <ProtectedRoute path="/create-skilllisting-gig-type">
              <RestrictSignUpRoutes>
                <NewCreateSkillListing />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-title">
              <RestrictSignUpRoutes>
                <NewCreateSkillListing />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-location">
              <RestrictSignUpRoutes>
                <NewCreateSkillListing />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-skill">
              <RestrictSignUpRoutes>
                <NewCreateSkillListing />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-salary">
              <RestrictSignUpRoutes>
                <NewCreateSkillListing />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-date">
              <RestrictSignUpRoutes>
                <NewCreateSkillListing />
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute
              path="/user-signup-success"
              component={SuccessPageSignUp}
            />
            <PublicRoute path="/employer/SHI">
              <EmployerLandingPage />
            </PublicRoute>
            <PublicRoute path="/employer/PRESIDIO">
              <EmployerLandingPage />
            </PublicRoute>
            <PublicRoute path="/employer/HEB">
              <EmployerLandingPage />
            </PublicRoute>


            <PublicRoute exact path="/license-number">
              <LicenseNumberPage/>
            </PublicRoute>
            <PublicRoute exact path="/basic-life-support">
              <BasicLifeSupport/>
            </PublicRoute>
           <PublicRoute exact path="/advanced-life-support">
              <AdvancedLifeSupport/>
            </PublicRoute>
            <PublicRoute exact path="/driver-license">
              <DriversLicense/>
            </PublicRoute>


            <SpecialRoute path="/terms">
              <TermsOfServices />
            </SpecialRoute>
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
      {location.includes("messages") && width <= 768 ? null : props.authToken &&
        WizardFlow !== 1000 ? null : (
        <Footer />
      )}
    </>
  );
};
