import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import BinaryButtons from "./Components/BinaryButtons/BinaryButtons";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import { Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateSignupProfile } from "../../redux/actions";
import errorIcon from "../../assets/img/ci_warning.png";
import "./SignUp.css";
import "./License.css";
import "../SignUp/NewCreateSkillListing/components/CreateSkillListing/CreateSkillListingScreen.css";

function LicenseScreen() {
  const windowHeight = window.innerHeight;
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  useEffect(() => {
    if (profiledata?.profile?.BackgroundCheck) {
      const temp =
        profiledata?.profile?.BackgroundCheck?.toLowerCase() === "yes"
          ? "Yes"
          : "No";
      // setSelected(temp);
    }
  }, [profiledata]);
  useEffect(() => {
    if (apiSuccess && flag) {
      setFlag(false);
      setError(false);
      history.push("/bls-doc");
    }
  }, [apiSuccess]);
  const nextHandler = () => {
    setFlag(true);
    if (!selected || selected.length <= 0 || selected === null || selected === "null") {
      setError(true);
    } else {
      setError(false);
      dispatch(
        updateSignupProfile({
          "Profiles.BackgroundCheck": selected.toLowerCase(),
          WizardFlow: 109,
        })
      );
    }
  };
  return (
    <div className="NameScreenContainer" style={{ minHeight: windowHeight }}>
      {typeof apiError === "string" && (
        <Alert
          message={apiError}
          type="error"
          className="alert-error"
          showIcon
          closable
        />
      )}
      <ProfileCompletion currenPage={9} totalPage={10} />
      <div className="license-wrapper">
        <div className="license-inner-container">
          <div className="license-title">
            Do you have any background or state licensing issues?
            <span className="mandatory-text">(Mandatory)</span>
          </div>
          <BinaryButtons
            label1={"Yes"}
            label2={"No"}
            selected={selected}
            setSelected={setSelected}
            error={error}
            setError={setError}
          />
        </div>
        {error && (
          <div className="error-msg-content">
            <img src={errorIcon} alt="" />
            <span className="error-text">
              Please select the license type to continue
            </span>
          </div>
        )}
      </div>
      <BottomButtons
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        backHandler={() => history.push("/resume-upload-v2")}
      />
    </div>
  );
}

export default LicenseScreen;
