import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import "./Profession.css";

import healthCareIcon from "../../assets/icons/healthCareICON.svg";
import IT_icon from "../../assets/icons/IT_ICON.svg";
import freelancer from "../../assets/icons/IT_Freelancer_ICON.svg";
import { updateProfile, updateSignupProfile } from "../../redux/actions";

const Profession = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.profileReducer.user);

  const updateProfession = (ProfessionCode) => {
    dispatch(
      updateProfile({
        userId: user.id,
        Professions: { ProfessionCode: ProfessionCode },
        WizardFlow: 2,
      })
    );
    dispatch(
      updateSignupProfile({
        userId: user.id,
        Professions: { ProfessionCode: ProfessionCode },
        WizardFlow: 2,
      })
    );
    history.push("/read-rules");
    // history.push("/role-experience");
  };

  // const trackingID = process.env.REACT_APP_ADWORDS_ID;

  // const gtag_report_conversion_hc = async () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/KXQCCPvtxJIYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //
  //   }
  // };

  // const gtag_report_conversion_it = async () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/c1HRCO_txJIYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //
  //   }
  // };

  useEffect(() => {
    const utm_source = localStorage.getItem("utm_source");
    const utm_campaign = localStorage.getItem("utm_campaign");
    const utm_medium = localStorage.getItem("utm_medium");
    const utm_term = localStorage.getItem("utm_term");
    if (utm_source || utm_campaign || utm_medium || utm_term)
      dispatch(
        updateProfile({
          MCSource: utm_source,
          MCCampaign: utm_campaign,
          MCMedium: utm_medium,
          MCTerm: utm_term,
        })
      );
  }, []);

  return (
    <div className="ProfessionPage">
      <div className="profession">
        <div className="professionHeader">
          <div style={{ maxWidth: "28.1rem" }}>
            <div className="preferenceHeader">
            Choose a marketplace category that best suits your role.
            </div>
          </div>
        </div>
        {/* <section className="alignProfession"> */}
        <div className="chooseProfession">
          <div>
            <div
              className={`healthCarePro ${
                user?.profile?.ProfessionCode === "HC" &&
                "SelectedProfessionCode"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                // gtag_report_conversion_hc();
                updateProfession("HC");
              }}
            >
              <div>
                {" "}
                <img src={healthCareIcon} alt="healthCareIcon" />{" "}
              </div>
              <hr
                style={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                  color: "#E4E4E4",
                  opacity: "30%",
                }}
              />
              <div className="professiontxt">
                {" "}
                <span className="professionText">Healthcare</span>
              </div>
            </div>
            <div className="professionSubText">
              Our healthcare professionals range from Registered nurses and
              Travel nurses to Respiratory therapists.{" "}
            </div>{" "}
          </div>
          <div>
            <div
              className={`InfoTPro ${
                user?.profile?.ProfessionCode === "IT" &&
                "SelectedProfessionCode"
              }`}
              onClick={() => {
                // gtag_report_conversion_it();
                updateProfession("IT");
              }}
            >
              <div>
                {" "}
                <img src={IT_icon} alt="IT_icon" />{" "}
              </div>
              <hr
                style={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                  color: "#E4E4E4",
                  opacity: "30%",
                }}
              />
              <div className="professiontxt">
                {" "}
                <span className="professionText">IT</span>
              </div>
            </div>
            <div className="professionSubText">
              {" "}
              Our IT professionals can range from Developers and Designers to
              Project managers.
            </div>{" "}
          </div>

          {/* <div
            className="ITFreelancerPro"
            onClick={() => updateProfession("FL")}
          >
            <img src={freelancer} alt="freelancer" />
            <span className="professionDivider"></span>
            <span className="professionText">IT Freelancer</span>
          </div> */}
        </div>
        {/* </section> */}
      </div>
    </div>
  );
};

export default Profession;
