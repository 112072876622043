import React, { useEffect, useState } from "react";
import "./RoleExperienceNew.css";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
} from "antd";
import { Option } from "antd/lib/mentions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  fetchKeySearchLocation,
  fetchProfileExperience,
  fetchUserSkillListing,
  updateProfile,
} from "../../redux/actions";
import { getCountryPhoneCode, getFullStateName } from "./helper";
import { NumericInput } from "./NumericInput";
import { debounce } from "../../shared/helpers";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import Loader from "../../components/Loader/Loader";

const RoleExperienceNew = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const path = useLocation().pathname;
  const user = useSelector((state) => state.profileReducer.user);
  const checkWizardFlow = useSelector(
    (state) => state.profileReducer.user.profile.WizardFlow
  );

  const UserName = useSelector((state) => state.profileReducer.userNamedata);
  const [name, setusername] = useState("");
  const [ExperienceLevelValue, setYearsExperience] = useState(null);
  const [VisaStatusName, setVisaStatusName] = useState(
    user?.profile?.VisaStatusName
  );
  const [BackgroundCheck, setBackgroundCheck] = useState(
    user?.profile?.BackgroundCheck
  );
  const [defaultMobile, setdefaultMobile] = useState(
    user?.profile?.MobilePhone
  );
  const [MobilePhone, setPhoneNumber] = useState(
    user?.profile?.MobilePhone?.slice(-2)
  );
  const [SSN, setSSN] = useState(
    user?.profile?.SSN
  );
 
  const [WorkExperience, SetWorkExperience] = useState([]);
  const [Location, setLocation] = useState();
  const [CountryCodeValue, setCountryCodeValue] = useState("1");
  const [flagcode, setflagcode] = useState("US");
  const [errorColor, seterrorColor] = useState(false);
  const [ExperienceLevelObject, setExperienceLevelObject] = useState(
    user?.profile?.ExperienceLevelName
  );
  const [customMessage, setcustomMessage] = useState(false);
  const [LastNameerror, setLastNameerror] = useState(false);
  const [specialCharerror, setspecialCharerror] = useState(false);
  const [FlagToUpdate, setFlagToUpdate] = useState(false);
  const [noDataViewLocation, setNoDataViewLocation] = useState("");
  const { loading } = useSelector((state) => state.profileReducer);

  let defaultValues;

  const flagSelection = (
    <>
      <div
        className={`roleExperience-Flag-Mobile-code ${
          errorColor === true ? `Flag-Mobile-code-borderColor` : null
        }`}
      >
        <span>
          <img
            src={`https://www.worldatlas.com/r/w24/img/flag/${flagcode?.toLowerCase()}-flag.jpg`}
          />
        </span>
        <span className="roleExperience-Mobile-code">+{CountryCodeValue}</span>
      </div>
    </>
  );
  const formatSSN = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    if (cleaned.length > 9) {
      return SSN; 
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
    }
    return value;
  };
  useEffect(() => {
    const firstName = user?.profile?.FirstName ? user?.profile?.FirstName : "";
    const lastName = user?.profile?.LastName ? user?.profile?.LastName : "";
    const Name = firstName ? firstName.concat(" ", lastName) : "";
    const Secuirty= user?.profile?.SSN
    const userName = Name.toLowerCase();
    dispatch(fetchProfileExperience());
    defaultValues = {
      PhoneNumber: user?.profile?.MobilePhone
        ? user?.profile?.MobilePhone?.replace(/[- )(]/g, "")?.slice(-10)
        : null, 
      username: userName,
      LoctionValues: user?.profile?.Location?.LocationWithCountry
        ? user?.profile?.Location?.LocationWithCountry
        : null,
      ExperienceLevelName: user?.profile?.ExperienceLevelName,
      VisaStatus: user?.profile?.VisaStatusName,
      BackgroundCheck: user?.profile?.BackgroundCheck,
    SSN:Secuirty
    };
    form.setFieldsValue(defaultValues);
    setdefaultMobile(user?.profile?.MobilePhone);
    setusername(Name);
  }, [user?.profile?.FirstName]);

  useEffect(() => {
    setVisaStatusName(user?.profile?.VisaStatusName);
    setYearsExperience(user?.profile?.ExperienceLevelName);
    setPhoneNumber(user?.profile?.MobilePhone?.slice(-10));
    setBackgroundCheck(user?.profile?.BackgroundCheck);
    setflagcode(
      getFullStateName(user?.profile?.Location?.CountryName)
        ? getFullStateName(user?.profile?.Location?.CountryName)
        : "US"
    );
    setCountryCodeValue(
      getCountryPhoneCode(user?.profile?.Location?.CountryName)
        ? getCountryPhoneCode(user?.profile?.Location?.CountryName)
        : "1"
    );
    setExperienceLevelObject({
      ExperienceLevelCode: user?.profile?.ExperienceLevelCode,
      ExperienceLevelName: user?.profile?.ExperienceLevelName,
      ExperienceLevelId: user?.profile?.ExperienceLevelId,
    });
    setLocation(user?.profile?.Location);
    form.setFieldsValue(defaultValues);
  }, [user?.profile?.VisaStatusName]);
  const searchedLocation = useSelector(
    (state) => state.credentialingReducer.searchedLocation
  );
  const searchLoading = useSelector(
    (state) => state.credentialingReducer.searchLoading
  );
  const ExperienceData = useSelector((state) => state.profileReducer.user);
  const getloctionData = async (value) => {
    const loctionParse = JSON.parse(value);
    setLocation(JSON.parse(value));
    setflagcode(getFullStateName(loctionParse.CountryName));
    setCountryCodeValue(getCountryPhoneCode(loctionParse.CountryName));
    setFlagToUpdate(true);
  };

  useEffect(() => {
    const sortWork = ExperienceData.workExperience.sort(
      (a, b) => a.ExperienceLevelId - b.ExperienceLevelId
    );
    SetWorkExperience(sortWork);
  }, [ExperienceData.workExperience]);

  useEffect(() => {
    if (MobilePhone?.length > 9) {
      setcustomMessage(false);
    }
  }, [MobilePhone?.length]);

  const onSearchLocation = (input) => {
    setNoDataViewLocation(input);
    dispatch(fetchKeySearchLocation(input));
  };
 

  // useEffect(() => {
  //   dispatch(fetchKeySearchLocation(""));
  // }, []);

  const [form] = Form.useForm();

  const submitform = () => {
    if (MobilePhone?.length <= 9) {
      setcustomMessage(true);
    }
    const { ExperienceLevelCode, ExperienceLevelName, ExperienceLevelId } =
      ExperienceLevelObject;
    
    let firstName;
    let LastName;

    if (name?.trim()?.split(/\s+/)?.length > 0){
      firstName = name?.trim()?.split(/\s+/)[0];
      LastName = name?.trim()?.split(/\s+/)[1];
    }
    // const [firstName, LastName] = name.trim().split(" ");    
    if (
      MobilePhone.length > 9 &&
      BackgroundCheck &&
      Location &&
      VisaStatusName &&
      ExperienceLevelObject.ExperienceLevelId &&
      firstName &&
      LastName &&
      FlagToUpdate === true && 
      specialCharerror === false
    ) {
      dispatch(
        updateProfile(
          {
          userId: user.id,
          name: firstName + " " + LastName,
          WizardFlow: checkWizardFlow === 6 ? 6 : checkWizardFlow === 1000 ? 1000 : 3,
          Profiles: {
            ExperienceLevelId,
            ExperienceLevelCode,
            ExperienceLevelName,
            VisaStatusName,
            MobilePhone: CountryCodeValue + MobilePhone,
            Location: Location,
            BackgroundCheck,
            FirstName: firstName,
            LastName: LastName,
          },
          SSN:SSN,
        })
      );
      if (props.talentProfile) {
        props.setEditModalStateRL(false);
        props.setTheLoading(true);
      } else history.push("/create-skilllisting-signup");
    } else if (
      MobilePhone.length > 9 &&
      BackgroundCheck &&
      VisaStatusName &&
      Location &&
      ExperienceLevelObject.ExperienceLevelId &&
      firstName &&
      LastName &&
      FlagToUpdate === false
    ) {
      if (props.talentProfile) {
        props.setEditModalStateRL(false);
        props.setTheLoading(true);
      } else history.push("/create-skilllisting-signup");
    }
  };

  // const trackingID = process.env.REACT_APP_ADWORDS_ID;
  // const gtag_report_conversion_role_experience_page = async () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/I96xCPLtxJIYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //     
  //   }
  // };
  // useEffect(() => {}, []);
  return (
    <div
      className={
        path === "/role-experience"
          ? "roleExperiencePageNew roleExperienceNewMargin"
          : "roleExperiencePageNew"
      }
    >
      <div className="roleExperienceNew">
        {!props.talentProfile && (
          <div className="roleExperience-container padding-container">
            <span className="roleExperience-Heading">
              We'd love to know you better
            </span>
            <span className="roleExperience-Sub-Heading">
              Please provide your basic details
            </span>
          </div>
        )}
        <Form
          form={form}
          initialValues={{
            defaultValues,
          }}
          preserve={false}
        >
          <div className="roleExperiencePageNew-location radio-group padding-container">
            <Form.Item
              name="username"
              validateStatus={LastNameerror ? "error" : null}
              help={LastNameerror ? "Please add your last name" : null}
              rules={[
                {
                  validator: (_, value) => {
                    if (value && value.length > 50) {
                      setLastNameerror(false);
                      return Promise.reject("Name should be under 50 characters with space");
                    }
            
                    if (value && (value.match(/[0-9!.,-=;'`@#$%^&*()_+{}|:"<>?~[\]\\]/) || value.match(/\d/))) {
                      setspecialCharerror(true);
                      return Promise.reject("Avoid using special characters or numbers.");
                    } else {
                      setspecialCharerror(false);
                    }
                    if (!value) {
                      setLastNameerror(false);
                      return Promise.reject("Please add your name");
                    }
                    if (!value?.trim()?.split(/\s+/)[1]){
                      return Promise.reject("Please add your last name");
                    }
            
                    return Promise.resolve();
                  },
                },
              ]}
              label={
                <div>
                  {" "}
                  Add your name
                  <span className="req-roleExperiencePageNew">*</span>
                </div>
              }
            >
              <Input
                onChange={(e) => {
                  setusername(e.target.value);
                  setFlagToUpdate(true);
                }}
                placeholder="Name"
              />
            </Form.Item>

            <Form.Item
              label={
                <div>
                  Your current location
                  <span className="req-roleExperiencePageNew">*</span>
                </div>
              }
              name="LoctionValues"
              rules={[
                { required: true, message: "Please add your current location" },
              ]}
            >
              <Select
                placeholder="Add your current location"
                // onSearch={onSearchLocation}
                onSearch={debounce((val) => onSearchLocation(val))}
                showSearch
                showArrow={false}
                size="large"
                onChange={getloctionData}
                notFoundContent={
                  noDataViewLocation?.length > 0 ? (
                    <div>
                      {searchLoading || searchedLocation.length > 0 ? (
                        <Spin size="small" />
                      ) : (
                        "No data found!"
                      )}
                    </div>
                  ) : null
                }
                listHeight={160}
              >
                {searchedLocation.map((loc) => {
                  return (
                    <Option key={loc.Location} value={JSON.stringify(loc)}>
                      {loc.LocationWithCountry}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="roleExperience-Mobile-Number">
            <Form.Item
              name="PhoneNumber"
              validateStatus={customMessage ? "error" : null}
              help={customMessage ? "Please enter valid mobile number" : null}
              label={
                <div>
                  Mobile Number
                  <span className="req-roleExperiencePageNew">*</span>
                </div>
              }
              className="padding-container"
              rules={[
                { required: true, message: "Add your mobile number" },
                {
                  pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                  message: "Please enter valid mobile number",
                },
              ]}
            >
              <NumericInput
                flagSelection={flagSelection}
                value={MobilePhone}
                onChange={setPhoneNumber}
                 changesflag={setFlagToUpdate}
                setcustomMessage={setcustomMessage}
              />
            </Form.Item>
          </div>
          <div className="roleExperience-Year padding-container">
            <div className="roleExperience-Year-Heading">
              Years of experience
              <span>*</span>
            </div>
            <Form.Item
              name="ExperienceLevelName"
              rules={[
                {
                  required: true,
                  message: "How many years of experience do you have?",
                },
              ]}
            >
              <Space direction="vertical" style={{ display: "flex" }}>
                <Radio.Group
                  name="ExperienceLevelName"
                  className="radio-group"
                  onChange={(e) => {
                    setYearsExperience(e.target.value);
                    setFlagToUpdate(true);
                  }}
                  defaultValue={user?.profile?.ExperienceLevelName}
                  value={ExperienceLevelValue}
                >
                  {WorkExperience.length &&
                    WorkExperience.map((exp) => {
                      return (
                        <Radio
                          className="radio-styles"
                          value={exp.ExperienceLevelName}
                          key={exp.ExperienceLevelName}
                          onClick={() => {
                            setExperienceLevelObject(exp);
                          }}
                        >
                          {exp.ExperienceLevelName}
                        </Radio>
                      );
                    })}
                </Radio.Group>
              </Space>
            </Form.Item>
          </div>
          <div className="roleExperience-Year padding-container">
            <div className="roleExperience-Year-Heading">
              Will you now or in the future require sponsorship for employment
              status (e.g., H-1B visa status)?
              <span>*</span>
            </div>
            <Form.Item
              name="VisaStatus"
              rules={[
                {
                  required: true,
                  message: "Please provide your work authorization",
                },
              ]}
            >
              <Space direction="vertical" style={{ display: "flex" }}>
                <Radio.Group
                  name="VisaStatus"
                  className="radio-group"
                  onChange={(e) => {
                    setVisaStatusName(e.target.value);
                    setFlagToUpdate(true);
                  }}
                  value={VisaStatusName}
                >
                  <Radio className="radio-styles" value="US Resident/Citizen">
                    US Resident/Citizen
                  </Radio>
                  <Radio value="EAD" className="radio-styles">
                    EAD
                  </Radio>
                  <Radio className="radio-styles" value="H1-B">
                    H1 - B
                  </Radio>
                  {/* <Radio value="Remote Worker" className="radio-styles">
                    Remote worker
                  </Radio> */}
                </Radio.Group>
              </Space>
            </Form.Item>
          </div>
          <div className="roleExperience-Year padding-container">
            <div className="roleExperience-Year-Heading">
              Do you have any background or state licensing issues?
              <span>*</span>
            </div>
            <Form.Item
              name="BackgroundCheck"
              rules={[
                {
                  required: true,
                  message: "Please select a background licensing issues",
                },
              ]}
            >
              <Space direction="vertical" style={{ display: "flex" }}>
                <Radio.Group
                  name="BackgroundCheck"
                  className="radio-group"
                  onChange={(e) => {
                    setBackgroundCheck(e.target.value);
                    setFlagToUpdate(true);
                  }}
                  value={BackgroundCheck}
                >
                  <Radio className="radio-styles" value="Yes">
                    Yes
                  </Radio>
                  <Radio value="No" className="radio-styles">
                    No
                  </Radio>
                </Radio.Group>
              </Space>
              <div className="roleExperience-Year-Heading">
              SSN
              <div className="role_Experience ">
              <input
            type="text"
             className="SSN"
            value={SSN}
            onChange={(e) => {
              setSSN(formatSSN(e.target.value));
              setFlagToUpdate(true)
            }}
          />
              </div>
              </div>                            
            </Form.Item>
          </div>
          <Divider />
          <div className="roleExperienceNew-Button-section padding-container">
            <div
              onClick={() => {
                if (props.talentProfile)
                  return props.setEditModalStateRL(false);
                dispatch(
                  updateProfile({
                    userId: user.id,
                    WizardFlow: 8,
                  })
                );
                history.push("/effective-profile-info");
              }}
              className="roleExperienceNew-Button-Back"
            >
              {props.talentProfile ? "Cancel" : "Back"}
            </div>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                // if (path === "/role-experience") {
                //   gtag_report_conversion_role_experience_page();
                // }
                if (!FlagToUpdate && props.talentProfile) return;
                submitform();
              }}
              className={`roleExperienceNew-Button-Back-Next ${
                !FlagToUpdate && props.talentProfile
                  ? "disableClassRoleDiscipline click-disable"
                  : ""
              }`}
            >
              {props.talentProfile ? "Update" : "Next"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RoleExperienceNew;
