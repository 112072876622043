import { Button, Drawer, Radio, Row, Space } from 'antd';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./MobileViewFilter.css";
import FilterIcon from "../../../assets/icons/filtericon.svg";
import SortIcon from "../../../assets/icons/sorticon copy.svg";
// import SearchDropDown from "../../pages/SearchSkillListing/components/LocationFilter/SearchDropDown";
// import ExperienceFilter from "../../pages/SearchSkillListing/components/LocationFilter/ExperienceFilter";
import PrivateTag from '../../../assets/icons/PrivateTag.svg'
import { FILTER_KEYS } from '../AllGigsFilter';
import SkillDropDown from '../SkillDropDown/SkillDropDown';
import ExperienceFilter from '../ExperienceFilter/ExperienceFilter';
import SearchDropDown from '../SearchDropDown/SearchDropDown';
import { CloseOutlined } from '@ant-design/icons';
const MobileViewFilter = ({
  searchFilters,
  setGigType,
  GigType,
  gigStatus,
  setGigStatus,
  isMobileView,
  handleApplyDropDown,
  selectedCities,
  setSelectedCities,
  selectedStates,
  setSelectedStates,
  selectedSkill,
  setSelectedSkill,
  selectedExperience,
  setSelectedExperience,
  selectedCountries,
  setSelectedCountries,
  experienceOptions,
  selectedGigType,
  setSelectedGigType,
  applyMobileFilters,
  clearAll,
  sortType,
  setSortType,
  searchText,
  mobileButtonsPadding,
  showGigStaus,
  defaultParams={},
  isPrivateTalent=false,
  selectedEmployers,
  setSelectedEmployers
}) => {
  // const Count = useSelector((state) => state.manageJobsReducer.counts);
  const [open, setOpen] = useState(false);
  const [showSortDrawer, setShowSortDrawer] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  let userProfile = JSON.parse(localStorage.getItem("userProfile"));

  const showDrawer = () => {
    console.log('open', open)
    setOpen(true);
    setShowSortDrawer(false);
  };

  const onClose = () => {
    setOpen(false);
    setShowSortDrawer(false);
    getFilterCount();
  };

  const onChange = (e) => {
    setGigType(e.target.value);
  };

  const applyFilter = () => {
    onClose();
    getFilterCount();
    applyMobileFilters({
      isPrivateGig: GigType,
      _sort: sortType,
      ...searchFilters,
    });
  };
  const handleSortType = (e) => {
    // Setting only publised gigs for upcoming expiry
    if(e.target.value=='ENDING')
    setGigStatus('Published')
    setSortType(e.target.value);
  };

  const getFilterCount = () => {
    console.log('first', searchFilters)
    let count = 0;
    if (searchFilters?.countries?.length) {
      count++;
    }
    if (GigType) {
      count++;
    }
    if (gigStatus) {
      count++;
    }
    if (searchFilters?.cities?.length) {
      count++;
    }
    if (searchFilters?.regions?.length) {
      count++;
    }
    if (searchFilters?.Skills?.length) {
      count++;
    }
    if (searchFilters?.explevel) {
      count++;
    }
    if (searchFilters?.JobOrderTypeName) {
      count++;
    }
    if(searchFilters?.PvtEmpIds?.length){
      count++;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    getFilterCount();
  }, [searchFilters]);

  return (
    <div>
      {isMobileView && (
        <div
          className='search-mobile-filter'
        >
          <div className="left" onClick={showDrawer}>
            <div className='search-filter-container'>
            <img className={"filter-button-icon"} alt="" src={FilterIcon} />
            <div className={"text"}>
              Filter {filterCount > 0 ? `(${filterCount})` : null}
            </div>
            </div>
          </div>

          <div className='left'>
          <div
            className="mobile-location-button"
            onClick={() => setShowSortDrawer(true)}
          >
            <img className={"filter-button-icon"} alt="" src={SortIcon} />
            <div className={"filter-button-text"}>Sort</div>
          </div>
          </div>
        </div>
      )}
      <Drawer
        title={
          <div className="Drawer-filter-pvt-mkt">
            <div
              onClick={() => {
                clearAll();
              }}
            >
              Clear All
            </div>
            <div>Filter</div>
            <div>
            <CloseOutlined
              className="close-btn-icon"
              onClick={()=> {setOpen(false)}}
            />
            </div>
          </div>
        }
        height={"100%"}
        placement={"top"}
        closable={false}
        onClose={onClose}
        visible={isMobileView && open}
        key={"sort"}
        footer={
          <>
            <Button
              onClick={() => applyFilter()}
              className="footer-primary-pvt"
              type="primary"
            >
              <span className='apply-text'>Apply Filter</span>
            </Button>
          </>
        }
      >
        <div>
          {false ? <><div className="Mobile-filter-title-pvt">{"All gigs"}</div>
          <div className="Radio-grop-pvt-mkt">
            <Radio.Group onChange={onChange} value={GigType}>
              <Space className="Space-radio-pvt-mkt" direction="vertical">
                <Radio value={false}>
                  <span>All gigs</span>
                  <span>
                    {/* (
                    {Count?.totalCount
                      ? Count?.totalCount?.[0]?.allGigs
                        ? Count?.totalCount?.[0]?.allGigs
                        : 0
                      : 0}
                    ) */}
                  </span>
                </Radio>
                <Radio value={true}>
                  <span>
                    Private gigs{" "}
                    <img
                      style={{ marginTop: "3px", marginLeft: "3px" }}
                      src={PrivateTag}
                      alt="private"
                    />
                  </span>
                  <span>
                    {/* (
                    {Count?.totalCount
                      ? Count?.totalCount[0]?.isPrivate
                        ? Count?.totalCount[0]?.isPrivate
                        : 0
                      : 0}
                    ) */}
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
            </div></> : null}

          {/* Gig Status */}

          {showGigStaus && (
            <>
              {" "}
              <div className="Mobile-filter-title-pvt">Gig status</div>
              <div className="Radio-grop-pvt-mkt">
                <Radio.Group
                  onChange={(e) => setGigStatus(e?.target?.value)}
                  value={gigStatus||'All'}
                >
                  <Space className="Space-radio-pvt-mkt" direction="vertical">
                    <Radio value={false}>
                      <span>All</span>
                      <span>
                        {/* (
                        {Count?.totalCount
                          ? Count?.totalCount[0]?.all
                            ? Count?.totalCount[0]?.all
                            : 0
                          : 0}
                        ) */}
                      </span>
                    </Radio>
                    <Radio value={"Published"}>
                      <span>Published</span>
                      <span>
                        {/* (
                        {Count?.totalCount
                          ? Count?.totalCount[0]?.published
                            ? Count?.totalCount[0]?.published
                            : 0
                          : 0}
                        ) */}
                      </span>
                    </Radio>
                    <Radio value={"Expired"}>
                      <span>Expired</span>
                      <span>
                        {/* (
                        {Count?.totalCount
                          ? Count?.totalCount[0]?.expired
                            ? Count?.totalCount[0]?.expired
                            : 0
                          : 0}
                        ) */}
                      </span>
                    </Radio>
                    <Radio value={"Deleted"}>
                      <span>Deleted</span>
                      <span>
                        {/* (
                        {Count?.totalCount
                          ? Count?.totalCount[0]?.deleted
                            ? Count?.totalCount[0]?.deleted
                            : 0
                          : 0}
                        ) */}
                      </span>
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </>
          )}
          <div className="Mobile-filter-title-pvt">Gig Type</div>
          <ExperienceFilter
            label="Gig Type"
            isSelected={false}
            filterKey={FILTER_KEYS.JobOrderTypeName}
            isMobileView={true}
            handleApply={handleApplyDropDown}
            value={searchFilters?.JobOrderTypeName}
            selectedOptions={selectedGigType}
            setSelectedOptions={setSelectedGigType}
            defaultParams={defaultParams}
          />
          <div className="Mobile-filter-title-pvt" style={{paddingTop:"18px"}}>Skill</div>

          <SkillDropDown
            filterKey={"SkillsId"}
            isSelected={searchFilters?.SkillsId}
            label={"Skill"}
            paramKey="skill"
            isMobileView={true}
            // handleApply={() => {}}
            handleApply={handleApplyDropDown}
            searchFilters={searchFilters}
            selectedOptions={selectedSkill}
            setSelectedOptions={setSelectedSkill}
            drawerTitle="Select Skill/Skills"
            searchText={searchText}
            defaultParams={defaultParams}
            />
          <div className="Mobile-filter-title-pvt">Country</div>
          <SearchDropDown
            filterKey={"CountryId"}
            isSelected={true}
            label={"Country"}
            paramKey="country"
            isMobileView={true}
            handleApply={handleApplyDropDown}
            selectedOptions={selectedCountries}
            setSelectedOptions={setSelectedCountries}
            searchText={searchText}
            searchFilters={searchFilters}
            drawerTitle="Select Country/Countries"
            defaultParams={defaultParams}
            />
          <div className="Mobile-filter-title-pvt">State</div>
          <SearchDropDown
            filterKey={"RegionId"}
            isSelected={false}
            label={"State"}
            paramKey="region"
            handleApply={handleApplyDropDown}
            isMobileView={true}
            searchFilters={searchFilters}
            selectedOptions={selectedStates}
            setSelectedOptions={setSelectedStates}
            searchText={searchText}
            drawerTitle="Select State/States"
            defaultParams={defaultParams}
          />
          <div className="Mobile-filter-title-pvt">City</div>
          <SearchDropDown
            filterKey={"CityId"}
            isSelected={false}
            label={"City"}
            handleApply={handleApplyDropDown}
            isMobileView={true}
            searchFilters={searchFilters}
            selectedOptions={selectedCities}
            setSelectedOptions={setSelectedCities}
            searchText={searchText}
            drawerTitle="Select City/Cities"
            defaultParams={defaultParams}
          />
          <div className="Mobile-filter-title-pvt">Experience Level</div>

          <ExperienceFilter
            label="Experience Level"
            filterKey={FILTER_KEYS.ExperienceLevel}
            isSelected={false}
            isMobileView={true}
            handleApply={handleApplyDropDown}
            value={searchFilters.explevel}
            selectedOptions={selectedExperience}
            setSelectedOptions={setSelectedExperience}
            defaultParams={defaultParams}
            />

          <div className="Mobile-filter-title-pvt" style={{paddingTop:"24px"}}>Private Employer</div>
          <SearchDropDown
              filterKey={"PvtEmpIds"}
              isSelected={false}
              label={"Private Employers"}
              paramKey="PvtEmpIds"
              handleApply={handleApplyDropDown}
              isMobileView={true}
              searchFilters={searchFilters}
              selectedOptions={selectedEmployers}
              searchText={searchText}
              setSelectedOptions={setSelectedEmployers}
              defaultParams={defaultParams}
            />
        </div>
      </Drawer>

      <Drawer
        title={
          <div className="Drawer-filter-pvt-mkt">
            <div>Sort By</div>
            <div>
            <CloseOutlined
              className="close-btn-icon"
              onClick={()=> setShowSortDrawer(false)}
            />
            </div>
          </div>
        }
        height={"100%"}
        placement={"top"}
        closable={false}
        onClose={onClose}
        visible={isMobileView && showSortDrawer}
        key={"top"}
        footer={
          <>
            <Button
              onClick={() => applyFilter()}
              className="footer-primary-pvt"
              type="primary"
            >
              Show Results
            </Button>
          </>
        }
      >
        <div className="Radio-grop-pvt-mkt">
          <Radio.Group onChange={handleSortType} value={sortType}>
            <Space className="Space-radio-pvt-mkt" direction="vertical">
              {SORT_OPTIONS.map((el) => (
                <Radio value={el.value}>
                  <span>{el.title || "N/A"}</span>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </Drawer>
    </div>
  );
};

export const SORT_OPTIONS=[{title:'Most Recent',value:"MOST_RECENT"},{title:'Upcoming Expiry',value:"ENDING"}]

export default MobileViewFilter;