import React from "react";
import { Progress } from "antd";
import license from "../../assets/icons/License.svg";
import { useSelector } from "react-redux";

import "./ProgressBar.css";
const ProgressBar = ({ heading }) => {
  const { ProfessionCode = "IT", StarRating = 0 } = useSelector(
    (state) => state.profileReducer.user?.profile
  );
  if (ProfessionCode == "IT") return <></>;
  return (
    <div className="ProfileText">
      {heading}
      <div className="custom-progress-container">
        <Progress
          percent={(StarRating || 0) * 20}
          strokeColor="#118936"
          format={() => ""}
          
        />
        <span className="Rating-head">
          <img className="Rating" src={license} alt="License Icon" />
          <span>
            <p className="Rating-Number">{(StarRating || 0).toFixed(1)}</p>
          </span>
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
