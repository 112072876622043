import React from "react";
import UploadDocV2 from "../../UploadDoc";

const StateLisenceV2 = () => {
  const docInfo = [
    {
      "State licence - front": 89,
    },
    {
      "State licence - back": 90,
    },
  ];
  const styleContainer = {
    width :"337px"
  }
  return (
    <div className="acls-doc-container">
      <UploadDocV2
        docInfo={docInfo}
        backUrl="resume-upload-v2"
        nextUrl="bls-doc"
        // nextUrl="social-security-doc"
        currentPage={5}
        WizardFlow={112}
        IsMandatory={true}
        skipUrl={"bls-doc"}
        // skipUrl={"social-security-doc"}
        style={styleContainer}
      />
    </div>
  );
};

export default StateLisenceV2;
