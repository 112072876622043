import React, { useEffect, useState } from "react";
import "./DashBoardProfileV2.2.css";
import infoIcon from "../../../../assets/img/info.png";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import { useSelector } from "react-redux";
import { Rate } from "antd";
import { useHistory } from "react-router-dom";
import CircleProgressBar from "./ProgressBar/CircleProgressbar";
import CircularBar from "../misc/CircularBar";
import CircularBadge from "../misc/CircularBadge";
import { getCapitalisationFormat } from "../../../../shared/helpers";
import { DefaultsRating } from "../../../WelcomeWizard/helper";
import {EmployerAssociation} from './EmployerAssociation'


const DashBoardProfile = () => {
  const [dimensions, setDimensions] = useState(window.innerWidth);
 
  const history = useHistory();
  const user = useSelector((state) => state.profileReducer.user);
  const toTitleCaseGreater = (str) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const firstName = user?.profile?.FirstName ? user?.profile?.FirstName : "";
  const lastName = user?.profile?.LastName ? user?.profile?.LastName : "";
  const Name = firstName + " " + lastName;
  const resultCase = toTitleCaseGreater(Name);
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  const [IsOnline, setIsOnline] = useState(true);
  useEffect(() => {
    const checkNetworkStatus = () => setIsOnline(navigator.onLine);
    setInterval(checkNetworkStatus, 5000);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  const userRating = (rating, isResumeCompletion) => {
    // rating = isResumeCompletion ? Number(rating) + 1 : rating;
    return rating >= 5 ? 5 : rating;
  };

  const OnlineButton = (
    <>
      <div
        className={`DashBoard-Profile-User-online ${
          IsOnline ? "" : "DashBoard-Profile-User-offline"
        }`}
      >
        <span
          className={`DashBoard-Profile-User-online-dot ${
            IsOnline ? "" : "DashBoard-Profile-User-offline-dot"
          }`}
        ></span>
        <span className="DashBoard-Profile-User-online-Text">
          {IsOnline ? "ONLINE" : "OFFLINE"}
        </span>
      </div>
    </>
  );
  const EmployeeAssociation = useSelector(
    (state) => state.profileReducer?.user?.EmployeeAssociation || []
  );

  const getPositionParameter = (length) => { 
    switch(length)
    {
      case 1:
        return '10px' 
        case 2: return '55px' 
        case 3 : return '65px'
        case 4 : return  '80px'
        case 5 : return  '95px'
        default : return '110px'

    }
   }

  return (
    <div className="DashBoard-Profile-Container" >
      <div className="DashBoard-Profile-User-Info" >
        {dimensions > 1000 && OnlineButton}
        <div className="DashBoard-Profile-Avatar-section"  style={{position:'relative'}}>
          <div className="DashBoard-Profile-Avatar-section-Mobile" >
            <CircleProgressBar
              progress={
                Math.floor(profile?.ResumeCompletion)
                  ? Math.floor(profile?.ResumeCompletion)
                  : 0
              }
              size={dimensions > 1000 ? 100 : 80}
            />
            <div className="DashBoard-Profile-Avatar">
              <ProfileAvatar
                name={user?.profile?.FirstName}
                dot={false}
                size={window.innerWidth > 1000 ? 70 : 56}
                src={`${process.env.REACT_APP_S3_URL}/${user.profile.PictureUrl}`}
                isAvatarBgColor={true}
              />
            </div>
            {dimensions < 1000 && OnlineButton}
          </div>
           <div style={{position:'absolute', top: '-18px', right :getPositionParameter(EmployeeAssociation.length)}}>
          <EmployerAssociation />
            </div>
          <div className="DashBoard-Profile-user-Mobile">
            <div className="DashBoard-Profile-user-name">
              {getCapitalisationFormat(resultCase)}
            </div>
            <div className="DashBoard-Profile-user-Profession-Code">
              {user?.profile?.ProfessionCode}
            </div>
            <div className="DashBoard-Profile-user-rating-section">
              <div className="DashBoard-Profile-user-rating-star">
                <Rate count={1} size="small" disabled defaultValue={1} />
              </div>
              <div className="DashBoard-Profile-user-rating-number">
                {userRating(
                  user?.profile?.Rating ? user?.profile?.Rating : DefaultsRating,
                  Math.floor(profile?.ResumeCompletion) === 100
                )}{" "}
                out of 5
              </div>
            </div>
            <div
              onClick={() => history.push("/3d_resume")}
              className="DashBoard-Profile-user-Button"
            >
              <div className="DashBoard-Profile-user-Button-Completed-3DResume">
                {`${
                  Math.floor(profile?.ResumeCompletion) === 100
                    ? "View"
                    : "Complete"
                } 3D resume`}
              </div>
            </div>
          </div>
        </div>
       
      </div>
      {Math.floor(profile?.ResumeCompletion) !== 100 ? (
        <div className="DashBoard-Profile-info-section">
          <div className="DashBoard-Profile-info-icon">
            <img src={infoIcon} />
          </div>
          <div className="DashBoard-Profile-info-Text">
            Boost your rating by completing your 3D resume, plus get higher bids
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DashBoardProfile;
