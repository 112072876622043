import React, { useCallback, useEffect, useReducer, useState } from "react";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import "./MobileNumberOTP.css";
import { Input, Typography, Button, Statistic } from "antd";
import { Modal, Spin } from "antd";
import Loader from "../../../../components/Loader/Loader";
import { LoadingOutlined } from "@ant-design/icons";
import ProfileCompletion from "../ProfileCompletion/ProfileCompletion";
import BottomButtons from "../BottomBottons/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
import { getRemainingSeconds } from "../../../WelcomeWizard/helper";
import { alertNotification } from "../../../../components/notifications/alertNotification";
import { fetchVerifictionOTPInit } from "../../../../redux/actions";
import { MobileOTPSendAPI } from "../../../../API/API";
import ProgressBar from "../../../../components/RatingProgressBar/ProgressBar";

const { Title, Text } = Typography;
const { Countdown } = Statistic;

const MobileNumberOTP = ({
  isModalOpen,
  setIsModalOpen,
  // phoneNumber,
  countryCode,
  // OTPVerification,
  LoadingOtp,
}) => {
  const history = useHistory(); // Get the history object
  const location = useLocation();
  const dispatch = useDispatch();
  const OTPapiSuccess = useSelector((state) => state.authReducer.OTPVerfiction);
  const loadingVerificationOtp = useSelector(
    (state) => state.authReducer.loadingOTP
  );
  const profiledata = useSelector((state) => state.profileReducer.user);

  const phoneNumber =
    useSelector((state) => state.authReducer.phoneNumber) ||
    location?.state?.phoneNumber;

  const handleEditNumberClick = () => {
    history.push({
      pathname: "/mobileno",
      state: { phoneNumber: phoneNumber },
    });
  };
  const [resendLoading, setResendLoading] = useState(false);

  const [time, setTime] = useState(60);
  const [otpMatch, setOtpMatch] = useState(false);
  const [otp, setOtp] = useState("");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
  };

  const handleOtpChange = (e) => {
    setOtp(e);
    if (e.length == 4) {
      handleVerifyOtp(e);
    }
  };

  const startTimer = useCallback(() => {
    const currentTimestamp = Date.now();
    localStorage.setItem("otpTimer", currentTimestamp);
    setTime(getRemainingSeconds(currentTimestamp));
    const updateTime = () => {
      setTime((prev) => {
        if (prev <= 1) {
          clearInterval(intervalTimer);
          return 0;
        }
        return prev - 1;
      });
    };

    const intervalTimer = setInterval(updateTime, 1000);
  }, []);

  useEffect(() => {
    const storedTimestamp = Number(localStorage.getItem("otpTimer"));
    const remainingTime = getRemainingSeconds(storedTimestamp || Date.now());
    setTime(remainingTime);
    const updateTime = () => {
      setTime((prev) => {
        if (prev <= 1) {
          clearInterval(intervalTimer);
          return 0;
        }
        return prev - 1;
      });
    };

    const intervalTimer = setInterval(updateTime, 1000);

    return () => {
      clearInterval(intervalTimer);
    };
  }, []);

  const onClickResend = () => {
    setResendLoading(true);
    resendOtp();
  };

  const resendOtp = async () => {
    try {
      const response = await MobileOTPSendAPI({ MobilePhone: phoneNumber });
      if (response.status == 200) {
        alertNotification(
          "success",
          "OTP sent successfully",
          undefined,
          undefined,
          undefined,
          2
        );
        startTimer();
      }
    } catch {
    } finally {
      setResendLoading(false);
    }
  };

  const handleVerifyOtp = (_otp = otp) => {
    if (_otp.length != 4) return;
    if (loadingVerificationOtp) return;

    OTPVerification(_otp);
  };

  const OTPVerification = (_otp) => {
    dispatch(
      fetchVerifictionOTPInit({
        MobilePhone: phoneNumber,
        Otp: Number(_otp),
        WizardFlow: 105,
      })
    );
  };

  useEffect(() => {
    if (OTPapiSuccess?.status == "passed") {
      history.push("/explever");
      alertNotification("success", "Mobile number verified successfully");
    }
  }, [OTPapiSuccess?.status]);

  return (
    <div className="MainContainer">
      <div className="subContainer">
        {profiledata?.profile?.ProfessionCode == "HC" ? (
          <div className="Profile-Completion-Parent">
            <div className="ProfileCompletion">
              <ProgressBar heading={"Your contact info"} />
            </div>
          </div>
        ) : (
          <ProfileCompletion currenPage={5} totalPage={10} />
        )}
        <div className="MobileNumberOTP-cantiner">
          <div className="MobileNumberOTP-Text-section">
            OTP sent to +{phoneNumber}{" "}
            <div className="mandatoryText">(Mandatory)</div>
          </div>

          <div className="Mobile-code-verification-enter-text">
            Enter the SMS verification code
          </div>
          <div
            className={`Mobile-code-verification-enter ${
              otpMatch ? "OTP-margin-true" : null
            }`}
          >
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              inputType={"tel"}
              numInputs={4}
              shouldAutoFocus
              renderInput={(props) => <input {...props} />}
              inputStyle={`OtpInput-number ${
                otpMatch ? "OtpInput-number-failed" : null
              }`}
            />
          </div>
          {time > 0 ? (
            <p className="retryText">
              You can retry after {formatTime(time)} sec
            </p>
          ) : (
            <Button
              disabled={resendLoading}
              className="edit-number"
              onClick={onClickResend}
            >
              Resend code
            </Button>
          )}
          <div className="edit-number" onClick={handleEditNumberClick}>
            Edit mobile number
          </div>
        </div>
        <BottomButtons
          nextButtonText="Verify"
          backHandler={handleEditNumberClick}
          nextBtnLoading={loadingVerificationOtp}
          nextHandler={handleVerifyOtp}
        />
      </div>
    </div>
  );
};
export default MobileNumberOTP;
