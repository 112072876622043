import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Modal } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";

import { Auth } from "../../../config/aws-exports";
import LostPassword from "./LostPassword";

import { setCookie } from "../../../shared/helpers";
import { VscLock } from "react-icons/vsc";

import "./SignUpLogin.css";
import GreenCheck from "../../../assets/icons/typcn_tick.svg";
import { GoAlert } from "react-icons/go";
import {
  isModalVisibleAuth as isParentModalVisible,
  isModalVisibleAuthResetPassword,
  userClicked,
} from "../../../redux/actions";
import Infomessage from "../../../components/Infomessage";
import {
  APP_NAME,
  EVENTS,
  PAGE,
  PAGE_TYPE,
} from "../../../constants/userTrackingConstants";

const Login = ({ employerTitle, isStatic = false }) => {
  const getRememberMe = JSON.parse(localStorage.getItem("RememberMe"));
  const uuid = useSelector((state) => state.userTrackingReducer.uuid);
  let InitialValue = !getRememberMe ? false : getRememberMe;
  const history = useHistory();
  const dispatch = useDispatch();

  const setIsParentModalVisibleAuth = (params) => {
    dispatch(isParentModalVisible(params));
  };

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(false);
  const [isremember, setIsRemember] = useState(InitialValue);
  const [errormessage, setErrorMessage] = useState("");
  const [isModalVisibleAuth, setIsModalVisibleAuth] = useState(false);

  const [greenCheck, setGreenCheck] = useState(false);
  const [showCheckIconEmail, setShowCheckIconEmail] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [email, setEmail] = useState("");
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  const GetEmail = localStorage.getItem("Email");
  //
  const GetPassword = localStorage.getItem("password");
  // //
  const isModalVisibleAuthRestPassword = useSelector(
    (state) => state.authReducer.isModalVisibleAuthRestPassword
  );

  useEffect(() => {
    dispatch(isModalVisibleAuthResetPassword(isModalVisibleAuth));
  }, [isModalVisibleAuth]);

  const remember = (e) => {
    if (e.target.checked === true) {
      setIsRemember(true);
      localStorage.setItem("RememberMe", true);
    } else if (e.target.checked === false) {
      setIsRemember(false);
      localStorage.setItem("Email", "");
      localStorage.setItem("password", "");
      localStorage.setItem("RememberMe", false);
    }
    setIsRemember(!isremember);
    //
  };

  const showModal = () => {
    // dispatch(isModalVisibleAuth(true));
    setIsModalVisibleAuth(true);
  };

  const modalCancel = () => {
    // dispatch(isModalVisibleAuth(false));
    setIsModalVisibleAuth(false);
    form.resetFields();
    setBackClicked(true);
  };
  const lostPassToLogin = (data) => {
    // //
    if (data === "Success") {
      setTimeout(() => {
        setIsModalVisibleAuth(false);
      }, 3000);
    }
  };
  function onLinkClick(e) {
    e.preventDefault();
    // further processing happens here
  }

  const forgetpassword = () => {
    history.push("/forgot");
    // if (window.innerWidth < 768) {
    //   history.push("/forgot");
    // } else {
    //   showModal();
    // }
  };

  // const lostPass = () => {
  //   history.push("/forgot");
  // };

  // const enterEmail = <div className="enterEmail">Enter your email</div>;

  const validateEmail = (e) => {
    const email = e.target.value;
    setEmail(e.target.value);
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.length > 0) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(email)) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  };

  const validatePassword = (e) => {
    const password = e.target.value;
    if (password.length === 0) setPasswordValidation(false);

    //  const valid = /^\S{8,}$/;
    //  //

    // if (valid.test(password)) {
    //   setPasswordValidation(false);
    // } else {
    //   setPasswordValidation(true);
    // }
  };

  const setEmployerDetailsToLocalStorage = () => {
    try {
      // if popup showing employer title we save employer details to local storage
      if (!employerTitle) return;
      const empDet = JSON.parse(localStorage.getItem("empDet"));
      localStorage.setItem("socialSignUpWithEmp", JSON.stringify(empDet));
    } catch (error) {
      console.warn("Error# 532143 ");
    }
  };

  const onLogin = async ({ email, password }) => {
    localStorage.setItem("Email", email);
    localStorage.setItem("password", password);

    setCount(false);
    setLoading(true);
    // //

    try {
      setEmployerDetailsToLocalStorage();
      const response = await Auth.signIn({ username: email, password });
      //
      // https://serverless-idm.awssecworkshops.com/01-user-auth/#validate-sign-up-and-sign-in
      const session = await Auth.currentSession();
      // //
      //
      //   "Cognito User Identity Token:",
      //   session.getIdToken().getJwtToken()
      // );
      if (isremember) {
        //
        const result = await Auth.rememberDevice();
        //

        if (result == "SUCCESS") {
          setCookie("RememberMe", "true");
        }
      }
      // //
      setLoading(false);
      history.push("/auth");
    } catch (error) {
      setLoading(false);
      setCount(true);
      // message.error(error.message);
      setPasswordValidation(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
      //
      if (isremember) {
        //
      }
    }
  };

  useEffect(() => {
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (GetEmail?.length > 0 && InitialValue === true) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(GetEmail) && InitialValue === true) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  }, []);

  
  // useEffect(() => {
  //   setIsParentModalVisibleAuth(!isModalVisibleAuth);
  // }, [isModalVisibleAuth]);

  return (
    <div className="loginSkillgigs">
      <Form
        name="normal_login"
        className="formLogin"
        initialValues={{
          remember: InitialValue,
          email: InitialValue === true ? GetEmail : "",
          password: InitialValue === true ? GetPassword : "",
        }}
        onFinish={onLogin}
        onLinkClick
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please add your email address",
            },
          ]}
          hasFeedback
          validateStatus={loading ? "validating" : null}
        >
          <Input
            // type="email"
            onChange={(e) => validateEmail(e)}
            className={`emailInputSignUp ${
              !emailFocused
                ? greenCheck && !passwordValidation
                  ? "validInputCSS"
                  : `${showCheckIconEmail ? "notValidInput" : null}`
                : null
            }`}
            loading={true}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => setEmailFocused(false)}
            placeholder="Enter your email"
            id="validating"
            suffix={
              greenCheck && !passwordValidation ? (
                <>
                  {!loading ? <img src={GreenCheck} alt="greenCheck" /> : null}
                </>
              ) : (
                <>
                  {showCheckIconEmail && !emailFocused ? (
                    <>
                      {!loading ? (
                        <GoAlert
                          style={{
                            color: "#db2828",
                            marginRight: "0.4rem",
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              )
            }
          />
        </Form.Item>
        {showCheckIconEmail && !greenCheck && !emailFocused && email?.length ? (
          <Infomessage
            message={"Looks like this email is incomplete"}
            class="container-error"
          />
        ) : null}
        <Form.Item
          name="password"
          help={
            passwordValidation ? (
              <div className="notValidColor">
                Incorrect username or password
              </div>
            ) : null
          }
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password
            // name="password"
            rules={[
              // {
              //   pattern: /^\S{8,}$/,
              //   message: "please use a valid password",
              // },
              {
                required: true,
              },
            ]}
            onChange={validatePassword}
            className={`passwordSignUp ${
              passwordValidation ? "notValidInput" : null
            } `}
            placeholder="Password"
            iconRender={(visible) =>
              visible ? (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#4054b2",
                  }}
                >
                  Hide
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#4054b2",
                  }}
                >
                  Show
                </span>
              )
            }
          />
        </Form.Item>
        <Form.Item>
        <Form.Item className="remeberForgotWRAP">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox  onChange={(e) => remember(e)}>
              <div className="remeberForgotCSS">
                <span>Remember me</span>
              </div>
            </Checkbox>
          </Form.Item>  

          <div
            className={`${isStatic ? "bl-color" : ""} forgotPassCSS`}
            // href=""
            onClick={forgetpassword}
            style={{ marginTop: "0.15rem"}}
          >
            Forgot password?
          </div>
        </Form.Item>
          <Button
            onClick={() => {
              ReactGA.event({
                category: "User Interaction",
                action:
                  (isStatic ? PAGE.STATIC : "") +
                  " " +
                  EVENTS.SIGN_IN_BUTTON_CLICK,
                label: "Signup Button",
              });

              dispatch(
                userClicked({
                  uuid: uuid,
                  event: EVENTS.SIGN_IN_BUTTON_CLICK,
                  pageType: PAGE_TYPE.SIGNIN,
                  page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                  appName: APP_NAME.TALENT,
                  url: window?.location?.href,
                })
              );
            }}
            type="primary"
            htmlType="submit"
            className={`login-form-button ${isStatic ? "bg-black-imp" : ""}`}
            disabled={loading}
            style={{ marginBottom: "0.7rem" , margintop:"1rem" }}
          >
            <span style={{ fontSize: "1rem" }}>
              {isStatic
                ? "Continue"
                : employerTitle
                ? `Sign into ${employerTitle}`
                : "Sign in"}
            </span>
          </Button>
        </Form.Item>
        
      </Form>
      {/* <Modal
        bodyStyle={{ padding: 0 }}
        width="24.37rem"
        visible={isModalVisibleAuthRestPassword}
        closable={false}
        footer={null}
        // style={{ top: 45, padding: 0 }}
        onCancel={modalCancel}
        maskClosable={true}
        centered={true}
        destroyOnClose={true}
        destroyPopupOnHide={true}
      >
        <LostPassword
          lostPassToLogin={lostPassToLogin}
          backClick={backClicked}
        />
        <div className="auth-divider-line"></div>
        <div
          className="bottom-text"
          onClick={() => {
            // history.push("/login");
            {
              modalCancel();
            }
          }}
        >
          Back to sign in
        </div>
      </Modal> */}
    </div>
  );
};

export default Login;
