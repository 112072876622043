import React, { useState } from "react";
import "./MultiSelect.css";
import { AutoComplete, Form, Input, Select, Spin } from "antd";
import { debounce } from "../../../../shared/helpers";
import { GoAlert } from "react-icons/go";
import { WarningFilled } from "@ant-design/icons";
function MultiSelect({
  title,
  type,
  placeholder,
  onChange,
  value,
  options,
  keyVal,
  selected,
  setSelected,
  error,
}) {
  const { Option } = Select;
  const [text,setText] = useState('');
  const test = placeholder.split(',')
  return (
    <div className="TextInputParentContainerSp">
      <div className="textInputContainer">
        <div className="TextInputTitleContainer">
          <div className="TextInputTitle-mutli">{title}</div>
          <div className="TextInputType-multi">({type})</div>
        </div>
        <div className="TextSubTitle">You can type & select multiple from a list of choices</div>
        <Form className={error ? "Multi-ant-form-error" : "Multi-ant-form"}>
          <Form.Item className={`MultiInputStyle${error ? 'error' : ""}`} name="addYourSkill">
            <Select
              notFoundContent={
                !options.length ? (
                  text?.length>1?<div>{false ? <Spin size="small" /> : "No data found!"}</div>:null
                ) : null
              }
              name="MultiSelect"
              placeholder={
                <div style={{display: 'flex', alignItems:'center'}}>
                <div style={{fontSize: '24px', fontWeight: '400', lineHeight: '28px', marginRight: '15px', color: '#7E7E7E'}}>ex</div> {test.map((t) => <div className="placeholderSkillListingFormTest">{t}</div>)}
                </div>
              }
              className={`MultiInputStyle${error ? 'error' : ""}`}
              // mode="multiple"
              mode="multiple"
              bordered={false}
              style={{ marginTop: 16 }}
              onSearch={debounce((val) => {onChange(val);setText(val)})}
              onChange={(e) => {setSelected(e)}}
              // value={selected}
              defaultValue={selected}
              // defaultOpen={selected}
              filterOption={false}
              status={error ? "error" : ""}
            >
              {options?.length > 0 &&
                options?.map((opt) => {
                  return (
                    <Option className={'multi-select-option'} key={opt} value={opt}>
                      {opt}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        {error && (
          <div className="errorMsg">
            <WarningFilled className="errorIcon" />
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
export default MultiSelect;
