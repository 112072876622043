import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";

import "./SignUpLogin.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Tabs,
  Tooltip,
  message,
  Alert,
  Divider,
} from "antd";
import "antd/dist/antd.css";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../config/aws-exports";
import {
  checkEmailFlow,
  checkUsernameUnique,
  fetchExistingtUser,
  userClicked,
} from "../../../redux/actions";
import Infomessage from "../../../components/Infomessage";

import GreenCheck from "../../../assets/icons/typcn_tick.svg";
import { GoAlert } from "react-icons/go";
import { debounce, setCookie } from "../../../shared/helpers";
import { BaseUrl } from "../../../components/Footer";
import userTrackingReducer from "./../../../redux/reducers/userTrackingReducer";
import {
  APP_NAME,
  EVENTS,
  PAGE,
  PAGE_TYPE,
} from "./../../../constants/userTrackingConstants";

const SignUp = ({ isStatic = false, ...props }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const getRememberMe = JSON.parse(localStorage.getItem("RememberMe"));
  let InitialValue = !getRememberMe ? false : getRememberMe;

  // const [passwordValidation, setPasswordValidation] = useState(false);

  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);
  const uuid = useSelector((state) => state.userTrackingReducer.uuid);
  const [userEmail, setUserEmail] = useState(null);
  const [count, setCount] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [check, setCheck] = useState(false);
  const [greenCheck, setGreenCheck] = useState(false);
  const [greenCheckUserName, setGreenCheckUserName] = useState(false);
  const [greenCheckPassword, setGreenCheckPassword] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [showCheckIconUserName, setShowCheckIconUserName] = useState(false);
  const [showCheckIconEmail, setShowCheckIconEmail] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const emailValidation = useSelector((state) => state.chekckUserReducer.user);
  const [clickCount, setClickCont] = useState(1);
  const [emailss, setEmailss] = useState("");
  const [signUpCreds, setSignUpCreds] = useState(null);
  const [isremember, setIsRemember] = useState(InitialValue);

  // Controlled Values
  const [email, setEmail] = useState(null);
  const [passwordInp, setPasswordInp] = useState(null);

  // const [count, setCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);
  const [errorPass, setErrorPass] = useState("");
  const [emailFocused, setEmailFocused] = useState(false);
  const [emailError, setEmailError] = useState(null);
  // const [messagetype, setMessageType] = useState("container-error");
  const clicked = () => {
    setCheck(!check);
    // //
  };

  const textemail = <span>example: example@mail.com</span>;
  const textpass = <span>Minimum 8 characters required</span>;
  const buttonWidth = 70;

  const history = useHistory();
  const [mode, setMode] = useState("Login");
  const SwitchMode = (key) => {
    setMode(key);
  };

  const checkUserName = useSelector(
    (state) => state.checkUserNameReducer.valid
  );
  const validateEmail = (e) => {
    const email = e.target.value;
    setCount(false);
    setEmail(email);
    //
    const v2 = /^\S*$/;
    const valid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.length > 0) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(email) && v2.test(email)) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  };

  const validateUserName = (e) => {
    const name = e.target.value;
    const valid = /^.{3,20}$/;
    const userName = name.toLowerCase();
    dispatch(checkUsernameUnique(userName));
    // const valid = /^([a-zA-Z]{3,})+\s+([a-zA-Z]{3,})+$/;
    if (name.length > 0) {
      setShowCheckIconUserName(true);
    }
    if (valid.test(name)) {
      setGreenCheckUserName(true);
    } else {
      setGreenCheckUserName(false);
    }
  };

  const validatePassword = (e) => {
    setPasswordInp(e.target.value);
    const password = e.target.value;
    const v2 = /^\S{8,}$/;
    const valid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

    if (password.length > 0) {
      setShowCheckPassword(true);
    }
    if (valid.test(password) && v2.test(password)) {
      setGreenCheckPassword(true);
    } else {
      setGreenCheckPassword(false);
    }
  };

  const onLogin = async ({ email, password }) => {
    localStorage.setItem("Email", email);
    // localStorage.setItem("password", password);

    setCount(false);
    setLoading(true);
    // //

    try {
      const response = await Auth.signIn({ username: email, password });
      //
      // https://serverless-idm.awssecworkshops.com/01-user-auth/#validate-sign-up-and-sign-in
      const session = await Auth.currentSession();
      // //
      //
      //   "Cognito User Identity Token:",
      //   session.getIdToken().getJwtToken()
      // );
      if (isremember) {
        //
        const result = await Auth.rememberDevice();
        //

        if (result == "SUCCESS") {
          setCookie("RememberMe", "true");
        }
      }
      // //
      setLoading(false);
      history.push("/auth");
    } catch (error) {
      setLoading(false);
      setCount(true);
      // message.error(error.message);
      // setPasswordValidation(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
      //
      if (isremember) {
        //
      }
    }
  };

  const storeAsTalent = () => {
    try {
      // localStorage.setItem("SignUpPageType", 'talent-modal');
      localStorage.setItem("asTalent", true);
      localStorage.removeItem("asEmployer");
    } catch {}
  };

  const emailSave = ({ email, password }) => {
    if (count || !greenCheckPassword) {
      return;
    }
    var premail = email.toString().toLowerCase();
    setLoading(true);
    dispatch(fetchExistingtUser(premail));
    setUserEmail(premail);
    setSignUpCreds({
      email,
      password,
    });
  };
  useEffect(() => {
    setClickCont(clickCount + 1);
    //
    //   "signup email check",
    //   emailValidation?.userExist?.cognitoTriggerSource,
    //   emailValidation
    // );
    if (
      emailValidation?.userExist?.cognitoTriggerSource ===
        "PreSignUp_ExternalProvider" &&
      emailValidation?.userExist?.valid === true
    ) {
      setLoading(false);
      // setErrorMessage("Email already exists using social login");
      setErrorMessage(
        "Sorry, this email already registered please try new one"
      );
      setCount(true);
      setGreenCheck(false);
      setShowCheckIconEmail(true);
      setTimeout(() => {
        // setCount(false);
        // setGreenCheck(false);
        // setShowCheckIconEmail(false);
        dispatch(checkEmailFlow(false));
      }, 3000);
    } else if (emailValidation?.userExist?.valid === true) {
      setLoading(false);
      setErrorMessage(
        "Sorry, this email already registered please try new one"
      );
      setCount(true);
      dispatch(checkEmailFlow(false));
      setGreenCheck(false);
      setShowCheckIconEmail(true);
      setTimeout(() => {
        // setCount(false);
      }, 3000);
    } else if (
      emailValidation?.userExist?.response?.data?.valid === false &&
      userEmail
    ) {
      let { email, password } = signUpCreds;
      onSignup(email, password);
      dispatch(checkEmailFlow(true));
      setCheckEmail(true);
    }
  }, [emailValidation]);
  useEffect(() => {
    setCount(false);
  }, [signUpLogin]);
  useEffect(() => {
    // if (Number(clickCount) > 1 && emailValidation?.userExist?.valid) {
    //   dispatch(checkEmailFlow(false));
    // }
    if (props.signinClick) {
      // dispatch(checkEmailFlow(false));
      setCheckEmail(false);
      setUserEmail();
    }
  }, [props.signinClick]);

  const setEmployerDetailsToLocalStorage = () => {
    try {
      // if popup showing employer title we save employer details to local storage
      if (!props?.employerTitle) return;
      const empDet = JSON.parse(localStorage.getItem("empDet"));
      localStorage.setItem("socialSignUpWithEmp", JSON.stringify(empDet));
    } catch (error) {
      console.warn("Error# 532143 ");
    }
  };

  const onSignup = async (email, password) => {
    // debugger;
    // //

    // const email = userEmail;
    setLoading(true);
    // setCount(false);
    try {
      if (!count) {
        setEmployerDetailsToLocalStorage();
        storeAsTalent();
        const response = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            "custom:source": "talent-modal",
            name: "",
            // "custom:account_name": fullname,
            "custom:account_name": null,
            // name: fullname,
            // optional
            // optional - E.164 number convention
            // other custom attributes
          },
        });
        //
        let a = response.userSub;
        // //
        // const session = await Auth.currentSession();
        // //
        // //
        //   "Cognito User Identity Token:",
        //   session.getIdToken().getJwtToken()
        // );
        setLoading(false);
        // history.push("/auth");
        //

        onLogin({ email, password });

        // if (a != null) {
        //   // setCount(true);
        //   // setMessageType("container-success");
        //   // setErrorMessage("Please check your email to complete the signup");
        //   message.success("Please check your email to complete the signup");
        //   setTimeout(() => {
        //     // setCount(false);
        //   }, 5000);
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 6000);
        //   // //
        //   // //
        // }
      } else {
        // If the username variable is true, execute the catch block
        setLoading(false);

        //
        // message.error(
        //   "Username is already taken, please enter a new username."
        // );
        // setErrorMessage(
        //   "Username is already taken, please enter a new username."
        // );
        setCount(true);
        setTimeout(() => {
          form.resetFields();
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      // setCount(true);
      // setMessageType("container-error");
      //
      // message.error(error.message);
      // setErrorMessage(
      //   "Username is already taken, please enter a new username."
      // );
      setErrorMessage(
        "Sorry, this email already registered please try new one"
      );
      setCount(true);
      setTimeout(() => {
        form.resetFields();
      }, 3000);

      // alert(error.message);
    }
  };

  // useEffect(() => {
  //   const el = document.getElementsByClassName("ant-input");
  //   for (let i = 0; i < el.length; i++) {
  //     el[i].setAttribute("autocomplete", "false");
  //   }
  // }, []);

  const btnSubmit = () => {
    if (!isPassChanged) {
      setErrorPass("notValidColor");
    }
  };

  return (
    <div className="signup-form">
      <div className="container-auth">
        <>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: false,
            }}
            onFinish={emailSave}
          >
            {/* <div className="form-label">Email</div> */}
            <Tooltip placement="topRight" title={textemail} trigger="click">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please add your email address",
                  },
                ]}
              >
                <Input
                  defaultValue={email}
                  // type="email"
                  onChange={(e) => validateEmail(e)}
                  onFocus={() => setEmailFocused(true)}
                  onBlur={() => setEmailFocused(false)}
                  className={`emailInputSignUp 
                    ${
                      !emailFocused
                        ? greenCheck
                          ? "validInputCSS"
                          : `${showCheckIconEmail ? "notValidInput" : null}`
                        : null
                    }
                    `}
                  placeholder="Enter your email"
                  suffix={
                    greenCheck ? (
                      <img src={GreenCheck} alt="greenCheck" />
                    ) : (
                      <>
                        {showCheckIconEmail && !emailFocused ? (
                          <GoAlert
                            style={{
                              color: "#db2828",
                              marginRight: "0.4rem",
                            }}
                          />
                        ) : null}
                      </>
                    )
                  }
                />
              </Form.Item>
              {showCheckIconEmail &&
              !greenCheck &&
              !emailFocused &&
              email?.length &&
              !count ? (
                <Infomessage
                  message={"Looks like this email is incomplete"}
                  class="container-error"
                />
              ) : null}
              {count && (
                <Infomessage
                  message={errormessage}
                  class="container-error signup-error"
                />
              )}
            </Tooltip>

            <Form.Item
              name="password"
              // autoComplete="off"
              extra={
                <div
                  className={` ${errorPass}  passwordHelp ${
                    greenCheckPassword
                      ? `${null}`
                      : `${showCheckPassword ? "notValidColor" : null}`
                  }`}
                >
                  Password must be min 8 characters. Combine numbers, upper and
                  lowercase letters.
                </div>
              }
              rules={[
                // {
                //   pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                // },
                // {
                //   required: true,
                // },
                {
                  validator: async (_, value) => {
                    if (value == undefined) {
                      // setGreenCheckPassword(false);
                      // setShowCheckPassword(true)
                      return Promise.reject(
                        new Error("Please add your password")
                      );
                      // return Promise.reject(new Error("Please enter password"));
                    }
                    if (value.length < 1 || value == undefined) {
                      return Promise.reject(
                        new Error("Please add your password")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              // help={false}
            >
              <Input.Password
                autocomplete="new-password"
                onChange={validatePassword}
                onKeyDown={(e) => e.which === 32 && e.preventDefault()}
                // type="password"
                placeholder="Choose a Password"
                className={`passwordSignUp ${
                  greenCheckPassword
                    ? "validInputCSS"
                    : `${showCheckPassword ? "notValidInput" : null}`
                }`}
                iconRender={(visible) =>
                  visible ? (
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "right",
                        color: "#4054b2",
                      }}
                    >
                      Hide
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "right",
                        color: "#4054b2",
                      }}
                    >
                      Show
                    </span>
                  )
                }
              />
            </Form.Item>
            {/* <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={btnSubmit}
                  // disabled={loading || !check}
                  // disabled={loading}
                >
                  <span style={{ fontSize: "1rem" }}>Join</span>
                </Button>
              </Form.Item> */}
            <Form.Item>
            <div className="terms-and-services">
              {`By joining, I agree to SkillGigs`}
              <a
                className="subTerm"
                onClick={() => {
                  ReactGA.event({
                    category: "User Interaction",
                    action:
                      (isStatic ? PAGE.STATIC : "") +
                      " " +
                      EVENTS.TERMS_AND_SERVICES_CLICK,
                    label: "Signup Button",
                  });

                  dispatch(
                    userClicked({
                      uuid: uuid,
                      event: EVENTS.TERMS_AND_SERVICES_CLICK,
                      pageType: PAGE_TYPE.SIGNUP,
                      page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                      appName: APP_NAME.TALENT,
                      url: window?.location?.pathname,
                    })
                  );
                  window.location.replace(
                    "https://skillgigs.com/terms-of-service"
                  );
                }}
              >
                <u>Terms of service</u>
              </a>
            </div>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={`login-form-button ${
                  isStatic ? "bg-black-imp" : ""
                }`}
                onClick={() => {
                  ReactGA.event({
                    category: "User Interaction",
                    action:
                      (isStatic ? PAGE.STATIC : "") +
                      " " +
                      "Sign Up Button Click",
                    label: "Signup Button",
                  });

                  dispatch(
                    userClicked({
                      uuid: uuid,
                      event: EVENTS.SIGN_UP_BUTTON_CLICK,
                      pageType: PAGE_TYPE.SIGNUP,
                      page: isStatic ? PAGE.STATIC : PAGE.MODAL,
                      appName: APP_NAME.TALENT,
                      url: window?.location?.href,
                      email: email,
                      password: passwordInp,
                      formValidation: greenCheck && greenCheckPassword,
                    })
                  );
                }}
                // disabled={loading || !check}
                // style={{ fontFamily: "SFProText-Bold" }}
                // disabled={loading}
              >
                <span style={{ fontSize: "1rem" }}>
                  {loading ? (
                    <LoadingOutlined />
                  ) : isStatic ? (
                    "Continue"
                  ) : (
                    `Join ${props?.employerTitle || "SkillGigs"}`
                  )}
                </span>
              </Button>
              {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2rem",
                    margin: "1vh 0",
                    color: "#e73959",
                  }}
                >
                  {loading && <LoadingOutlined />}
                </div> */}
            </Form.Item>
          </Form>
        </>
      </div>
    </div>
  );
};

export default SignUp;
