export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

function SkillObject(props) {
  var Skills = [];
  for (var i = 0; i < props.length; i++) {
    Skills.push({
      SkillPercent: props[i]?.SkillPercent,
      SkillName: props[i]?.Skills?.SkillName,
    });
  }
  return Skills;
}

function ObjectDecleration(props, identity) {
  // 
  return identity === "EducationHistory"
    ? {
        YearCompleted: props?.YearCompleted,
        UserId: props?.CreatedBy,
        CourseType: props?.CourseTypes,
        Deleted: props?.Deleted,
        Courses: props?.Courses,
        Schools: props?.Schools,
        CreatedBy: props?.CreatedBy,
        Created: props?.Schools?.Created,
        ModifiedBy: props?.Schools?.ModifiedBy,
        _id: props?._id,
        Modified: props?.Modified,
      }
    : {
        UserId: props?._id,
        Title: props?.Title,
        Deleted: props?.Deleted,
        Description: props?.Description,
        CompanyName: props?.CompanyName,
        IndustryName: props?.IndustryName,
        StartDate: props?.StartDate,
        EndDate: props?.EndDate,
        Location: props?.Location,
        ProjectDescription: props?.ProjectDescription,
        Responsibilities: props?.Responsibilities,
        CurrentJob: props?.CurrentJob,
        CreatedBy: props?.CreatedBy,
        Created: props?.Created,
        ModifiedBy: props?.ModifiedBy,
        _id: props?._id,
        Skills: SkillObject(props?.Consultants_CareerRecordSkills),
        Consultants_CareerRecords_Responsibilities:
          props?.Consultants_CareerRecords_Responsibilities,
        Modified: props?.Modified,
        __v: 0,
      };
}

export function SkillIndexObjects(props) {
  var skillIndexs = [];
  // 
  for (var i = 0; i < props?.length; i++) {
    skillIndexs.push({
      name: props[i].key,
      value: props[i].percent,
    });
  }
  return skillIndexs;
}

export function EducationHistoryAPIStructure(props, identity) {
  // 
  var EducationDetailsArray = [];
  var WorkHistoryDetailsArray = [];
  for (var i = 0; i < props.length; i++) {
    props[i]?.YearCompleted &&
      EducationDetailsArray.push(
        ObjectDecleration(props[i], "EducationHistory")
      );
    identity &&
      WorkHistoryDetailsArray.push(ObjectDecleration(props[i], "WorkHistory"));
  }
  return identity ? WorkHistoryDetailsArray : EducationDetailsArray;
}

export function WorkHistoryAPIStructure(props, identity) {
  // 
  var WorkHistoryDetailsArray = [];
  for (var i = 0; i < props.length; i++) {
    identity &&
      WorkHistoryDetailsArray.push(ObjectDecleration(props[i], "WorkHistory"));
  }
  return WorkHistoryDetailsArray;
}

export function SocialAccountsLinkFB(props) {
  if (props[0]?.SocialMediaType?.SocialMediaTypeCode === "FB") {
    return (
      props[0]?.SocialMediaType?.SocialMediaTypeURL +
      props[0]?.SocialMediaTypeSlug
    );
  } else if (props[1]?.SocialMediaType?.SocialMediaTypeCode === "FB") {
    return (
      props[1]?.SocialMediaType?.SocialMediaTypeURL +
      props[1]?.SocialMediaTypeSlug
    );
  }
  else if (props[2]?.SocialMediaType?.SocialMediaTypeCode === "FB") {
    return (
      props[2]?.SocialMediaType?.SocialMediaTypeURL +
      props[2]?.SocialMediaTypeSlug
    );
  }
  else if (props[3]?.SocialMediaType?.SocialMediaTypeCode === "FB") {
    return (
      props[3]?.SocialMediaType?.SocialMediaTypeURL +
      props[3]?.SocialMediaTypeSlug
    );
  }
  return null;
}

export function SocialAccountsLinkLI(props, SocialType) {
  if (props[0]?.SocialMediaType?.SocialMediaTypeCode === "LI") {
    return (
      props[0]?.SocialMediaType?.SocialMediaTypeURL +
      props[0]?.SocialMediaTypeSlug
    );
  } else if (props[1]?.SocialMediaType?.SocialMediaTypeCode === "LI") {
    return (
      props[1]?.SocialMediaType?.SocialMediaTypeURL +
      props[1]?.SocialMediaTypeSlug
    );
  }
  else if (props[2]?.SocialMediaType?.SocialMediaTypeCode === "LI") {
    return (
      props[2]?.SocialMediaType?.SocialMediaTypeURL +
      props[2]?.SocialMediaTypeSlug
    );
  }
  else if (props[3]?.SocialMediaType?.SocialMediaTypeCode === "LI") {
    return (
      props[3]?.SocialMediaType?.SocialMediaTypeURL +
      props[3]?.SocialMediaTypeSlug
    );
  }
  return null;
}

export function SocialAccountsLinkGH(props, SocialType) {
  if (props[0]?.SocialMediaType?.SocialMediaTypeCode === "GH") {
    return (
      props[0]?.SocialMediaType?.SocialMediaTypeURL +
      props[0]?.SocialMediaTypeSlug
    );
  } else if (props[1]?.SocialMediaType?.SocialMediaTypeCode === "GH") {
    return (
      props[1]?.SocialMediaType?.SocialMediaTypeURL +
      props[1]?.SocialMediaTypeSlug
    );
  }
  else if (props[2]?.SocialMediaType?.SocialMediaTypeCode === "GH") {
    return (
      props[2]?.SocialMediaType?.SocialMediaTypeURL +
      props[2]?.SocialMediaTypeSlug
    );
  }
  else if (props[3]?.SocialMediaType?.SocialMediaTypeCode === "GH") {
    return (
      props[3]?.SocialMediaType?.SocialMediaTypeURL +
      props[3]?.SocialMediaTypeSlug
    );
  }
  return null;
}

export function SocialAccountsLinkSF(props, SocialType) {
  if (props[0]?.SocialMediaType?.SocialMediaTypeCode === "SF") {
    return (
      props[0]?.SocialMediaType?.SocialMediaTypeURL +
      props[0]?.SocialMediaTypeSlug
    );
  } else if (props[1]?.SocialMediaType?.SocialMediaTypeCode === "SF") {
    return (
      props[1]?.SocialMediaType?.SocialMediaTypeURL +
      props[1]?.SocialMediaTypeSlug
    );
  }
  else if (props[2]?.SocialMediaType?.SocialMediaTypeCode === "SF") {
    return (
      props[2]?.SocialMediaType?.SocialMediaTypeURL +
      props[2]?.SocialMediaTypeSlug
    );
  }
  else if (props[3]?.SocialMediaType?.SocialMediaTypeCode === "SF") {
    return (
      props[3]?.SocialMediaType?.SocialMediaTypeURL +
      props[3]?.SocialMediaTypeSlug
    );
  }
  return null;
}

export function ProfileAPIResponseStructure(props, propsDetails) {
  // 
  return {
    status: true,
    data: {
      statusCode: 200,
      body: {
        auth_time: 1671531034,
        email: props?.EmailAddress,
        sub: props?.data?.body?.sub,
        user: {
          EmployeeAssociation:props?.EmployeeAssociation,
          verification: {
            email: props?.verification?.email,
          },
          cognito: {
            cognitoUsername: props?.cognito?.cognitoUsername,
            triggerSource: props?.cognito?.triggerSource,
            userPoolId: props?.cognito?.userPoolId,
          },
          email: props?.EmailAddress,
          name: props?.name ? props?.name : props?.UserName,
          _id: props?._id,
          profile: {
            StarRating:props?.Profiles?.StarRating,
            EmployeeAssociation:props?.EmployeeAssociation,
            ProfileId: props?.profiles?.ProfileId,
            ProfileSocialMedia: props?.Profiles?.ProfileSocialMedia,
            AccountId: props?.AccountId,
            FirstName: props?.Profiles?.FirstName,
            LastName: props?.Profiles?.LastName,
            Facebook: SocialAccountsLinkFB(props?.Profiles?.ProfileSocialMedia),
            LinkedIn: SocialAccountsLinkLI(props?.Profiles?.ProfileSocialMedia),
            Github: SocialAccountsLinkGH(props?.Profiles?.ProfileSocialMedia),
            Stackoverflow: SocialAccountsLinkSF(props?.Profiles?.ProfileSocialMedia),
            Google: props?.Profiles?.Google,
            Apple: props?.Profiles?.Apple,
            Discipline: props?.Profiles?.Discipline,
            Speciality: props?.Profiles?.Speciality,
            WorkPlacePolicy: props?.Profiles?.WorkPlacePolicy,
            veteran: props?.Profiles?.veteran,
            CareerGap: props?.Profiles?.CareerGap,
            DraftSkillListing: props?.DraftSkillListing,
            MiddleInitial: props?.Profiles?.MiddleInitial,
            PreferredName: props?.name,
            UserName: props?.UserName,
            EmailAddress: props?.EmailAddress,
            Description: props?.Profiles?.Objective,
            AuthenticationProviderId: props?.AuthenticationProviderId,
            IsActive: props?.IsActive,
            IsConfirmed: props?.IsConfirmed,
            MasterAccountId: props?.MasterAccountId,
            LoginCount: props?.LoginCount,
            MCListId: props?.MCListId,
            ProfessionCode: props?.Professions?.ProfessionCode,
            ProfessionId: props?.Professions?.ProfessionId,
            SkillgigsEmail: props?.SkillgigsEmail,
            AlternateEmailAddress: props?.Profiles?.AlternateEmailAddress,
            HomePhone: props?.Profiles?.HomePhone,
            OfficePhone: props?.Profiles?.OfficePhone,
            MobilePhone: props?.Profiles?.MobilePhone,
            AddressLine1: props?.Profiles?.AddressLine1,
            AddressLine2: props?.Profiles?.AddressLine2,
            JobTitle: props?.Profiles?.JobTitle,
            ZipCode: props?.Profiles?.ZipCode,
            IndustryId: null,
            IndustryName: null,
            SourceId: null,
            PictureUrl: props?.Profiles?.PictureUrl,
            VideoUrl: props?.Profiles?.VideoUrl,
            WebUrl: props?.Profiles?.WebUrl,
            CareerManagerId: props?.Profiles?.CareerManagerId,
            Created: props?.Profiles?.Created,
            Modified: props?.Profiles?.Modified,
            Rating: props?.Profiles?.Rating,
            RMVResultID: props?.Profiles?.RMVResultID,
            Objective: props?.Profiles?.Objective,
            TagLine: props?.Profiles?.TagLine,
            Gender: props?.Profiles?.Gender,
            DateOfBirth: props?.Profiles?.DateOfBirth,
            ExperienceLevels: props?.Profiles?.ExperienceLevels,
            ExperienceLevelId:
              props?.Profiles?.ExperienceLevels?.ExperienceLevelId,
            ExperienceLevelCode:
              props?.Profiles?.ExperienceLevels?.ExperienceLevelCode,
            ExperienceLevelName:
              props?.Profiles?.ExperienceLevels?.ExperienceLevelName,
            CompanyDescription: props?.Profiles?.CompanyDescription,
            CompanySizeId: props?.Profiles?.CompanySizeId,
            CompanySize: "251 - 500",
            CompanyTypeId: props?.Profiles?.CompanyTypeId,
            CompanyType: "Series A Startup",
            YearFounded: props?.Profiles?.YearFounded,
            LinkedInImport: props?.Profiles?.LinkedInImport,
            VisaStatusId: props?.Profiles?.VisaStatuses?.VisaStatusId,
            VisaStatusCode: props?.Profiles?.VisaStatuses?.VisaStatusCode,
            VisaStatusName: props?.Profiles?.VisaStatuses?.VisaStatusName,
            CityId: null,
            CareerHistory: props?.Profiles?.CareerHistory,
            ResumeFilename: props?.Profiles?.ResumeFilename,
            DoNotSMS: props?.Profiles?.DoNotSMS,
            CompanyName: props?.Profiles?.CompanyName,
            LocationWithCountry: props?.Profiles?.LocationWithCountry,
            Cities: props?.Profiles?.Cities,
            Countries: props?.Profiles?.Countries,
            Regions: props?.Profiles?.Regions,
            WizardFlow: props?.WizardFlow,
            UrlFriendlyName: props?.Profiles?.UrlFriendlyName,
            Approved: props?.Approved,
            ApprovedOn: props?.ApprovedOn,
            ApprovedBy: props?.ApprovedBy,
            LastLoginDate: props?.LastLoginDate,
            LastSuccessfulLoginDate: props?.LastSuccessfulLoginDate,
            TCVersion: props?.TCVersion,
            TCAcceptedOn: props?.TCAcceptedOn,
            MarqueePictureId: props?.Profiles?.MarqueePictureId,
            CountryName: null,
            RegionName: null,
            ResponseRate: props?.Profiles?.ResponseRate,
            AccountTypeCode: props?.AccountTypeCode,
            Role: [props?.AccountRoles[0]?.Roles?.RoleCode],
            notificationToken: props?.Profiles?.notificationToken,
            PreferredCurrency: props?.Profiles?.PreferredCurrency,
            RecentJobSearch: props?.Profiles?.RecentJobSearch,
            BackgroundCheck: props?.Profiles?.BackgroundCheck,
            _id: props?.Profiles?._id,
            Location: props?.Profiles?.Location,
            [propsDetails?.EducationHistory]:
              props?.Profiles?.Consultants_AcademicRecords &&
              EducationHistoryAPIStructure(
                props?.Profiles?.Consultants_AcademicRecords
              ),
            JobCompletion: props?.Profiles?.JobCompletion,
            Trending: props?.Profiles?.Trending,
            IndustryDensity: props?.Profiles?.IndustryDensity,
            Earned: props?.Profiles?.Earned,
            PlacedBid: props?.Profiles?.PlacedBid,
            ResumeCompletion: props?.Profiles?.ProfileComletion
              ? props?.Profiles?.ProfileComletion
              : null,
            Skilllistings: props?.Profiles?.Skilllistings,
            SkillListings: props?.Profiles?.SkillListings,
            Views: props?.Profiles?.Views,
            Followers: props?.Profiles?.Followers,
            WorkHistory:
              props?.Profiles?.Consultants_CareerRecords &&
              WorkHistoryAPIStructure(
                props?.Profiles?.Consultants_CareerRecords,
                "WorkHistory"
              ),
            skillIndex: props?.Profiles?.skillIndex,
            // skillIndex: SkillIndexObjects(props?.Profiles?.skillIndex),
            Certificate: props?.Profiles?.Consultants_Certificates,
            verification: props.verification,
            user_doc_list: props?.Profiles?.user_doc_list,
            total_doc_list: props?.Profiles?.total_doc_list,
            ProfileViews: props?.Profiles?.ProfileViews,
            ResumeDocID: props?.ResumeDocID,
            TalentDocuments: props?.Profiles?.TalentDocuments,
            CareerGap: props?.Profiles?.CareerGap,
            SSN:props?.SSN,
            IsVerifiedMobileNumber:props?.Profiles?.IsVerifiedMobileNumber
          },
          created_at: props?.AccountRoles[0]?.Created,
          updated_at: props?.Modified,
        },
        isValid: props?.isValid,
      },
    },
  };
}

export const getPictureUrl = (pictureUrl='') => { 
  if(!pictureUrl) return 
  if (pictureUrl?.charAt(0) === "/") {
    return `${process.env.REACT_APP_S3_URL}${pictureUrl}`;
  } else {
    return `${process.env.REACT_APP_S3_URL}/${pictureUrl}`;
  }
 }


export const GetQueryParams = (query = null) => {
  return (
    (query || window.location.search.replace("?", ""))
      // get array of KeyValue pairs
      .split("&")
      // Decode values
      .map((data) => {
        let [key, val] = data.split("=");
        return [key, decodeURIComponent(val || "")];
      })
      // array to object
      .reduce((result, [key, val]) => {
        result[key] = val;
        return result;
      }, {})
  );
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "";
};

export function calculateFinalAmount(originalAmount, skillgigsFeeRate, payrollFeeRate) {
  skillgigsFeeRate /= 100;
  payrollFeeRate /= 100;

  let finalAmount = originalAmount / (1 - skillgigsFeeRate) / (1 - payrollFeeRate);

  return finalAmount // Return the final amount rounded to 2 decimal places
}