import React, { useState, useEffect } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import backbtn from '../../../../../assets/icons/backbtn.svg';

const BottomButtons = (props) => {
    const [isMobileView, setIsMobileView] = useState(false);
    const location = useLocation();
    const isFinishRoute = location.pathname === '/create-skilllisting-date';

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); 

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {!isMobileView ? (
                <div className={`create-skill-listing-buttons${props.isGigType ? "" : " bottom-gap"}${props.isTitlePage ? " isTitlePage" : ""}`}>
                    <div onClick={!props.loading ? props.handleBack : null} className="back-button">
                        Back
                    </div>
                    <div onClick={!props.loading ? props.handleNext : null} className="next-button">
                        {props.loading && <LoadingOutlined style={{ fontSize: 24, marginRight: "4px" }} spin />}
                        {isFinishRoute ? "Finish" : "Next"}
                    </div>
                    {props?.Skip && (
                        <div onClick={props.Skiphandle} className="skip-button">
                            Skip
                        </div>
                    )}
                </div>
            ) : (
                <div className="main-div-mobile1">
                    <div className="button-mob-view1">
                        <button onClick={props.handleBack} className="back-btn-mobile1" style={{ width: '16%', marginRight: '3%' }}>
                            <img src={backbtn} alt="Back" />
                        </button>
                        {props?.Skip && (
                            <>
                                <button className="skip-btn-mobile1" onClick={props.Skiphandle} style={{ width: '36%', marginRight: '3%' }}>
                                    Skip
                                </button>
                                <button
                                    onClick={props.handleNext}
                                    className="next-btn-mobile1"
                                    style={{ width: '28%' }}
                                >
                                    {props.loading && <LoadingOutlined style={{ fontSize: 24, marginRight: "4px" }} spin />}
                                    <span className="btn-read-rules1">{isFinishRoute ? "Finish" : "Next"}</span>
                                </button>
                            </>
                        )}
                        {!props?.Skip && (
                            <button
                                onClick={props.handleNext}
                                className="next-btn-mobile1"
                                style={{ width: '70%' }}
                            >
                                {props.loading && <LoadingOutlined style={{ fontSize: 24, marginRight: "4px" }} spin />}
                                <span className="btn-read-rules1">{isFinishRoute ? "Finish" : "Next"}</span>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomButtons;
