import { Button, Col, Row } from "antd";
import React from "react";
import "./BottomButtons.css";
import backbtn from "../../../../assets/icons/backbtn.svg"

function BottomButtons({
  backHandler,
  nextHandler,
  bottom,
  nextBtnLoading,
  nextButtonText = "Next",
  backButtonText = "Back",
}) {
  const dimension = window.innerWidth;
  return (
    <div className="BottomButtonsParentContainer"
      style={dimension > 768 ? { bottom: bottom ? "-4rem" : 0, position: "absolute" } : {}}>
      <div className="buttonsRowContainer">
        <Row className={"Btns-row1"}>
          {dimension > 768 && (
            <Col lg={11} xs={24} md={10} className={"Back-btn-div"}>
              <Button className="Back-btn" onClick={backHandler}>
                {backButtonText}
              </Button>
            </Col>
          )}
          {dimension > 768 && (
            <div className="temp-gap-div" ></div>
          )}
          {
            dimension > 768 && (
              <Col lg={11} xs={24} md={10} className={"Next-btn-div"}>
                <Button className="Next-btn" onClick={nextHandler} loading={nextBtnLoading}>
                {nextButtonText}
                </Button>
              </Col>
            )
          }
          {dimension <= 768 ?
            <div className="main-div-mobile">
              <div className="button-mob-view">
                <button onClick={backHandler} className="back-btn-mobile">
                  <img src={backbtn} />
                </button>
                <button
                  onClick={nextHandler}
                  className="next-btn-mobile"
                >
                  <span className="btn-read-rules">{nextButtonText}</span>
                </button>
              </div> 
            </div> : null
          }
        </Row>
      </div>
    </div>
  );
}

export default BottomButtons;
